import { call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { authApi as api } from '@anews/api'
import { User } from '@anews/types'

import i18n from '../../i18n'
import { setTag } from '../../sentry'

import { AuthActionMap, AuthActions, AuthActionType, NotificationActions } from '../actions'

import { notifyDaysToExpire } from '../actions/license-actions'

import { createRootSaga } from './helpers'

const {
  kickOutFailure,
  kickOutSuccess,
  listAuthoritiesFailure,
  listAuthoritiesSuccess,
  loadAuth,
  loadAuthFailure,
  loadAuthSuccess,
  loginSuccess,
  loginFailure,
  logoutFailure,
  logoutSuccess,
  isFirstLogin,
} = AuthActions

const { notifyError, notifyWarning } = NotificationActions

/* Watchers */

function* listAuthoritiesSaga(): Generator {
  try {
    const authorities = yield call(api.authorities)
    yield put(listAuthoritiesSuccess(authorities as string[]))
  } catch (error) {
    yield put(listAuthoritiesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* loadAuthSaga(): Generator {
  try {
    const user = yield call(api.me)
    yield put(loadAuthSuccess(user as User))
    setTag('nickname', (user as User).nickname)

    yield delay(5000) // delay para evitar problemas com a tradução nao carregada
    yield put(notifyDaysToExpire())
  } catch (error) {
    yield put(loadAuthFailure(error))
    // não exibe mensagem de erro - apenas leva para tela de login
  }
}

// Possiveis erros em login
const ERROR_UNAUTHORIZED = 'error_unauthorized'
const ERROR_MAX_USER_LIMIT = 'error_max_user_limit'
const ERROR_LICENSE_INVALID = 'error_license_invalid'
const ERROR_RESET_PASSWORD = 'error_reset_password'

function* loginSaga(action: AuthActionMap<AuthActionType.LOGIN_REQUEST>): Generator {
  const { username, password, isResetPassword } = action.credentials
  try {
    yield call(api.login, username, password, isResetPassword)
    yield put(loginSuccess())
    yield put(loadAuth())
  } catch (error) {
    yield put(loginFailure(error))

    const errorResponse = error?.response?.data || ''
    switch (errorResponse) {
      case ERROR_MAX_USER_LIMIT:
        // Licença Max usuarios atingido
        yield put(
          notifyWarning({
            message: i18n.t('license:maxLoginReached'),
            description: i18n.t('license:contact'),
          }),
        )
        break

      case ERROR_LICENSE_INVALID:
        // Licença Invalida
        yield put(
          notifyWarning({
            message: i18n.t('license:expiredMsg'),
            description: i18n.t('license:contact'),
          }),
        )
        break

      case ERROR_RESET_PASSWORD:
        // Precisa de reset de senha
        yield put(isFirstLogin(true))
        yield put(
          notifyWarning({
            message: i18n.t('login:warnNewPassword'),
            description: i18n.t('login:warnNewPasswordDesc'),
          }),
        )

        break

      case ERROR_UNAUTHORIZED:
      default:
        if (isResetPassword) {
          // Reset de senha com erro ou nao permitido, senha ja definida
          yield put(
            notifyError({
              message: i18n.t('login:errNewPassword'),
              description: i18n.t('login:errNewPasswordDesc'),
            }),
          )
        } else {
          yield put(
            notifyWarning({
              message: i18n.t('error:operation'),
              description: i18n.t('login:authFailed'),
              error,
            }),
          )
        }
        break
    }
  }
}

function* logoutSaga(): Generator {
  try {
    yield call(api.logout)
    yield put(logoutSuccess())
    yield put(loadAuth())
  } catch (error) {
    yield put(logoutFailure(error))
    yield put(
      notifyWarning({
        message: i18n.t('error:operation'),
        description: i18n.t('error:actionFailed'),
        error,
      }),
    )
  }
}

function* kickOutSaga(action: AuthActionMap<AuthActionType.KICKOUT_REQUEST>): Generator {
  try {
    yield call(api.kickOut, action.id)
    yield put(kickOutSuccess(action.id))
  } catch (error) {
    yield put(kickOutFailure(error))
    yield put(
      notifyWarning({
        message: i18n.t('error:operation'),
        description: i18n.t('error:actionFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(AuthActionType.LIST_AUTHORITIES_REQUEST, listAuthoritiesSaga)
  },
  function* () {
    yield takeLatest(AuthActionType.LOAD_AUTH_REQUEST, loadAuthSaga)
  },
  function* () {
    yield takeLatest(AuthActionType.LOGIN_REQUEST, loginSaga)
  },
  function* () {
    yield takeLatest(AuthActionType.LOGOUT_REQUEST, logoutSaga)
  },
  function* () {
    yield takeEvery(AuthActionType.KICKOUT_REQUEST, kickOutSaga)
  },
])
