import React, { memo, useRef } from 'react'
import { Carousel } from 'react-responsive-carousel'

import Empty from 'antd/es/empty'
import Modal from 'antd/es/modal'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './Carousel.css'

interface Props {
  content: any[]
  visible: boolean
  title: string
  onClose: () => void
}

const CarouselModal = ({ content, visible, title, onClose }: Props) => {
  const ref = useRef<any>()

  return (
    <Modal
      title={title}
      width="50%"
      visible={visible}
      footer={null}
      maskClosable={false}
      onCancel={onClose}
      destroyOnClose
      bodyStyle={{ padding: '0 24px 4px 24px' }}
    >
      {content?.length ? (
        <Carousel
          ref={ref}
          showArrows
          infiniteLoop
          transitionTime={0}
          showThumbs={false}
          showStatus={false}
        >
          {content.map((el, idx) => (
            <div key={el?.id || idx}>{el}</div>
          ))}
        </Carousel>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Modal>
  )
}

export default memo(CarouselModal)
