import React, { memo, useCallback, useState } from 'react'
import styled from '@emotion/styled'
import AntTextArea from 'antd/es/input/TextArea'

import { toSentenceCase } from '@anews/utils'

import theme from '../../theme'

import Button from './Button'
import HGroup from './HGroup'
import TextArea from './TextArea'

const ButtonBar = styled(HGroup)`
  background-color: ${theme.backgrounds.light};
  border: 1px solid ${theme.border};
  border-bottom: 0;
  padding: 4px;

  & .ant-btn {
    border: 0;
    height: 20px;
  }
`

interface Props extends React.ComponentProps<typeof AntTextArea> {
  caseOptions?: boolean
}

const CaseTextArea = ({ caseOptions, value, onChange, disabled, style, ...rest }: Props) => {
  const [selectionStart, setSelectionStart] = useState(0)
  const [selectionEnd, setSelectionEnd] = useState(0)

  const onChangeHandler = useCallback(
    (e: any) => {
      onChange && onChange(e)
    },
    [onChange],
  )

  const onSelectionHandler = useCallback((e: React.SyntheticEvent<HTMLTextAreaElement, Event>) => {
    const target = e.target as HTMLTextAreaElement
    setSelectionStart(target.selectionStart)
    setSelectionEnd(target.selectionEnd)
  }, [])

  const getSplit = useCallback(
    (val?: string) => {
      if (selectionStart === selectionEnd) {
        return {
          first: '',
          selected: val || '',
          last: '',
        }
      }
      return {
        first: val?.substr(0, selectionStart) || '',
        selected: val?.substr(selectionStart, selectionEnd - selectionStart) || '',
        last: val?.substr(selectionEnd, val.length - selectionEnd) || '',
      }
    },
    [selectionStart, selectionEnd],
  )

  const onUpperCase = useCallback(() => {
    if (typeof value === 'string') {
      const split = getSplit(value)
      const newValue = split.first + split.selected.toUpperCase() + split.last

      onChangeHandler({
        target: { value: newValue },
      } as any)
    }
  }, [value, getSplit, onChangeHandler])

  const onSentenceCase = useCallback(() => {
    if (typeof value === 'string') {
      const split = getSplit(value)
      const sentence = toSentenceCase(split.selected)

      const newValue = split.first + sentence + split.last

      onChangeHandler({
        target: { value: newValue },
      } as any)
    }
  }, [value, getSplit, onChangeHandler])

  const onLowerCase = useCallback(() => {
    if (typeof value === 'string') {
      const split = getSplit(value)
      const newValue = split.first + split.selected.toLowerCase() + split.last

      onChangeHandler({
        target: { value: newValue },
      } as any)
    }
  }, [value, getSplit, onChangeHandler])

  const finalStyle = {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    backgroundColor: theme.backgrounds.component,
    ...(style || {}),
  }

  return (
    <>
      {caseOptions && !disabled && (
        <ButtonBar>
          <Button onClick={onUpperCase} size="small">
            AA
          </Button>
          <Button onClick={onSentenceCase} size="small">
            Aa
          </Button>
          <Button onClick={onLowerCase} size="small">
            aa
          </Button>
        </ButtonBar>
      )}
      <TextArea
        style={finalStyle}
        onSelect={onSelectionHandler}
        onChange={onChangeHandler}
        disabled={disabled}
        value={value}
        {...rest}
      />
    </>
  )
}

export default memo(CaseTextArea)
