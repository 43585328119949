import styled from '@emotion/styled'
import React, { memo, useCallback } from 'react'
import { BaseEditor, Descendant, Editor, Text, Transforms } from 'slate'
import { Editable, ReactEditor, Slate } from 'slate-react'

import { HistoryEditor } from 'slate-history'

import { RichTextLeaf, RichTextBlock, EMPTY_RICH_TEXT } from '@anews/types'

import { useOnce } from '@anews/hooks'

import i18n from '../../../i18n'

import HoveringToolBar from './HoveringToolBar'

export * from './RichTextViewer'

const StyledEditable = styled(Editable)`
  border: 0;
  padding: 0;
  margin-bottom: 24px !important;
  resize: none;
  cursor: text !important;
`

export const richTextPalette = {
  comment: '#0050B3',
  colorPicker: {
    black: '#000000',
    red: '#FF0000',
    lightGreen: '#37D67A',
    purple: '#BA68C8',
    lightBlue: '#2CCCE4',
  },
}

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor
    Element: RichTextBlock
    Text: RichTextLeaf
  }
}

export function toggleFormat(editor: Editor, format: string, color?: string) {
  const [isActive] = Editor.nodes(editor, {
    match: node => Text.isText(node) && node[format] === true,
    mode: 'all',
  })
  const changes = color ? { color } : { [format]: !isActive }
  Transforms.setNodes(editor, changes, {
    match: node => Text.isText(node),
    split: true,
  })
}

interface Props {
  onChange?: (content: Descendant[]) => void
  onSelect?: () => void
  value: Descendant[] | undefined
  editor: Editor
}

/**
 * Precisei declarar o editor fora do componente, por causa dos botões laterais, como toggleCase()
 */
function RichTextEditor({ onChange, onSelect, value, editor }: Props) {
  useOnce(() => Editor.normalize(editor, { force: true }))

  const renderLeaf = useCallback(({ children, leaf, attributes }) => {
    const style: React.CSSProperties = {
      fontStyle: leaf.italic ? 'italic' : 'normal',
      fontWeight: leaf.bold ? 'bold' : 'normal',
      color: leaf.color || 'inherit',
      textDecoration: leaf.underline ? 'underline' : 'inherit',
    }
    return (
      <span style={style} {...attributes}>
        {children}
      </span>
    )
  }, [])

  return (
    <Slate
      editor={editor}
      value={!value || value.length < 1 ? EMPTY_RICH_TEXT : value}
      onChange={content => {
        const isAstChange = editor.operations.some(op => op.type !== 'set_selection')
        onSelect?.()
        if (isAstChange) {
          onChange?.(content)
        }
      }}
    >
      <HoveringToolBar />
      <StyledEditable
        renderLeaf={renderLeaf}
        className="configFontSize"
        placeholder={i18n.t('phrases:typePlaceholder')}
        onKeyDown={event => {
          if (event.ctrlKey) {
            switch (event.key) {
              case 'z':
                event.preventDefault()
                HistoryEditor.undo(editor)
                break
              case 'b':
                event.preventDefault()
                toggleFormat(editor, 'bold')
                break
              case 'i':
                event.preventDefault()
                toggleFormat(editor, 'italic')
                break
              case 'u':
                event.preventDefault()
                toggleFormat(editor, 'underline')
                break
              default:
                break
            }
          }
        }}
      />
    </Slate>
  )
}

export default memo(RichTextEditor)
