import { call, put, takeLatest } from 'redux-saga/effects'

import { reportApi as api } from '@anews/api'
import { Report, Page, ReportRevision, ReportConfig } from '@anews/types'

import { isNew } from '@anews/utils'

import {
  EntityLockActions,
  NotificationActions,
  ReportActionType as ActionType,
  ReportActionMap as ActionMap,
  ReportActions,
} from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { renewLock } = EntityLockActions
const { notifyError } = NotificationActions

const {
  filterReportsSuccess,
  filterReportsFailure,
  loadReportSuccess,
  loadReportFailure,
  loadReportRevisionsSuccess,
  loadReportRevisionsFailure,
  createReportSuccess,
  createReportFailure,
  updateReportSuccess,
  updateReportFailure,
  removeReportsSuccess,
  removeReportsFailure,
  loadConfigSuccess,
  loadConfigFailure,
  updateConfigSuccess,
  updateConfigFailure,
  createConfigSuccess,
  createConfigFailure,
} = ReportActions
/* Watchers */

function* filterReportsSaga(action: ActionMap<ActionType.FILTER_REQUEST>): Generator {
  try {
    const result = yield call(api.pageFilter, action.params, action.page, action.size)
    yield put(filterReportsSuccess(result as Page<Report>))
  } catch (error) {
    yield put(filterReportsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* loadReportSaga(action: ActionMap<ActionType.LOAD_REQUEST>): Generator {
  try {
    if (typeof action.target === 'number') {
      const report = yield call(api.load, action.target, action.edit)
      yield put(loadReportSuccess(report as Report, action.edit))
    } else {
      const report = action.target
      if (!isNew(report) && action.edit) {
        yield put(renewLock(report.uuid))
      }
      yield put(loadReportSuccess(report, action.edit))
    }
  } catch (error) {
    yield put(loadReportFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* loadRevisionsSaga(action: ActionMap<ActionType.LOAD_REVISIONS_REQUEST>): Generator {
  try {
    const revisions = yield call(api.loadChangesHistory, action.reportId)
    yield put(loadReportRevisionsSuccess(revisions as ReportRevision[]))
  } catch (error) {
    yield put(loadReportRevisionsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createReportSaga(action: ActionMap<ActionType.CREATE_REQUEST>): Generator {
  try {
    const report = yield call(api.create, action.report, action.lock)
    yield put(createReportSuccess(report as Report))
  } catch (error) {
    yield put(createReportFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateReportSaga(action: ActionMap<ActionType.UPDATE_REQUEST>): Generator {
  try {
    const report = yield call(api.update, action.report)
    yield put(updateReportSuccess(report as Report))
  } catch (error) {
    yield put(updateReportFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeReportsSaga(action: ActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    const ids = yield call(api.remove, action.ids)
    yield put(removeReportsSuccess(ids as number[]))
  } catch (error) {
    yield put(removeReportsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

function* loadConfigSaga(): Generator {
  try {
    const config = yield call(api.loadConfig)
    yield put(loadConfigSuccess(config as ReportConfig))
  } catch (error) {
    yield put(loadConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createConfigSaga(action: ActionMap<ActionType.CREATE_CONFIG_REQUEST>): Generator {
  try {
    const config = yield call(api.createConfig, action.config)
    yield put(createConfigSuccess(config as ReportConfig))
  } catch (error) {
    yield put(createConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateConfigSaga(action: ActionMap<ActionType.UPDATE_CONFIG_REQUEST>): Generator {
  try {
    const config = yield call(api.updateConfig, action.config)
    yield put(updateConfigSuccess(config as ReportConfig))
  } catch (error) {
    yield put(updateConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.FILTER_REQUEST, filterReportsSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_REQUEST, loadReportSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_REVISIONS_REQUEST, loadRevisionsSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createReportSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateReportSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeReportsSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_CONFIG_REQUEST, loadConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_CONFIG_REQUEST, createConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_CONFIG_REQUEST, updateConfigSaga)
  },
])
