import React, { PureComponent } from 'react'
import { Moment } from 'moment'

import AntDatePicker from 'antd/es/date-picker'
import { PickerProps } from 'antd/es/date-picker/generatePicker'

class DatePicker extends PureComponent<PickerProps<Moment>> {
  render() {
    return (
      <AntDatePicker
        format="L"
        style={{ minWidth: 104 }}
        // css={css`
        //   min-width: 120px;
        // `}
        // disabledTime={disabledTime || defaultDisabledTime}
        {...this.props}
      />
    )
  }
}

export default DatePicker
