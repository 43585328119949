export var CompanyType;
(function (CompanyType) {
    CompanyType["CONTRIBUTOR"] = "CONTRIBUTOR";
    CompanyType["REMOTE"] = "REMOTE";
})(CompanyType || (CompanyType = {}));
export var CompanyData;
(function (CompanyData) {
    CompanyData["PROGRAMS"] = "programs";
    CompanyData["USERS"] = "users";
    CompanyData["EDITORS"] = "users/editors";
    CompanyData["PRODUCERS"] = "users/producers";
    CompanyData["REPORTERS"] = "users/reporters";
    CompanyData["CLASSIFICATIONS"] = "classifications";
    CompanyData["VEHICLES"] = "vehicles";
    CompanyData["TYPES"] = "types";
    CompanyData["DOCUMENT_CONFIG"] = "documents/config";
    CompanyData["GUIDELINE_CONFIG"] = "assignments/config";
    CompanyData["REPORTAGE_CONFIG"] = "news_reports/config";
    CompanyData["STORY_CONFIG"] = "stories/config";
    CompanyData["RUNDOWN_CONFIG"] = "rundowns/config";
    CompanyData["COMPANIES"] = "remote";
    CompanyData["EDITORIALS"] = "editorials";
    CompanyData["TEAMS"] = "teams";
})(CompanyData || (CompanyData = {}));
