import { call, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects'

import { rundownApi as api } from '@anews/api'
import { Rundown, RundownConfig, Block, Story, TranslationDictionary } from '@anews/types'

import { RundownActionMap, RundownActions, RundownActionType } from '../actions/rundown-actions'
import { NotificationActions } from '../actions'

import { RootState } from '../reducers'
import { RundownStateTab, RundownOperationState } from '../reducers/rundowns-reducer'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError, notifyInfo, notifySuccess, notifyWarning } = NotificationActions

const {
  loadConfigSuccess,
  loadConfigFailure,
  createConfigSuccess,
  createConfigFailure,
  updateConfigSuccess,
  updateConfigFailure,
  loadRundown,
  loadRundownSuccess,
  patchRundownSuccess,
  patchRundownFailure,
  moveStoriesSuccess,
  moveStoriesFailure,
  patchBlockFailure,
  patchBlockSuccess,
  createRundownSuccess,
  createRundownFailure,
  createBlockSuccess,
  createBlockFailure,
  createStorySuccess,
  createStoryFailure,
  createStoryAfterSuccess,
  createStoryAfterFailure,
  removeBlockFailure,
  removeBlockSuccess,
  moveStories,
  copyPasteStories,
  copyPasteStoriesSuccess,
  copyPasteStoriesFailure,
  repaginateSuccess,
  repaginateFailure,
  approveRundownSuccess,
  approveRundownFailure,
  approveRundownBlockSuccess,
  approveRundownBlockFailure,
  loadRundownFailure,
  toggleBlockStatusSuccess,
  toggleBlockStatusFailure,
  toggleCurrentStatusSuccess,
  toggleCurrentStatusFailure,
  runMosActionSuccess,
  runMosActionFailure,
  sendRundownCreditsSuccess,
  sendRundownCreditsFailure,
  loadTpContentSuccess,
  loadTpContentFailure,
  enableMosSuccess,
  enableMosFailure,
  disableMosSuccess,
  disableMosFailure,
} = RundownActions
/* Selectors */

const rundownTabsList = (state: RootState) => state.rundowns.tabs.list

const operationData = (state: RootState) => state.rundowns.operation

/* Watchers */

//
//  Rundown
//

function* loadRundownSaga(action: RundownActionMap<RundownActionType.LOAD_REQUEST>): Generator {
  try {
    const rundown = yield call(api.load, action.date, action.programId)
    yield put(loadRundownSuccess(rundown as Rundown, action.uuid, action.date, action.programId))
  } catch (error: any) {
    yield put(loadRundownFailure(error))
    yield put(
      notifyWarning({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createRundownSaga(action: RundownActionMap<RundownActionType.CREATE_REQUEST>): Generator {
  try {
    const rundown = yield call(api.create, action.date, action.programId, action.templateId)
    yield put(createRundownSuccess(rundown as Rundown, action.uuid, action.date, action.programId))
  } catch (error: any) {
    yield put(createRundownFailure(error))
    yield put(
      notifyWarning({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* patchRundownSaga(action: RundownActionMap<RundownActionType.PATCH_REQUEST>): Generator {
  try {
    yield call(api.patchRundown, action.rundownId, action.field, action.newValue)
    yield put(patchRundownSuccess())
  } catch (error: any) {
    yield put(
      patchRundownFailure(error, action.rundownId, action.field, action.newValue, action.oldValue),
    )
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* enableMosSaga(action: RundownActionMap<RundownActionType.ENABLE_MOS_REQUEST>): Generator {
  try {
    const result = yield call(api.enableMos, action.rundownId)
    const devicesNames = result as string[]
    yield put(enableMosSuccess(devicesNames as string[]))

    const count = devicesNames.length
    const devices = devicesNames.join(', ')

    yield put(
      notifySuccess(
        count > 0
          ? {
              message: i18n.t('words:success'),
              description: i18n.t('rundown:enableMosSuccess', { count, devices }),
            }
          : {
              message: i18n.t('words:info'),
              description: i18n.t('rundown:enableMosSuccessEmpty'),
            },
      ),
    )
  } catch (error: any) {
    yield put(enableMosFailure(error))
    yield put(
      notifyWarning({
        message: i18n.t('error:operation'),
        description: i18n.t('error:actionFailed'),
        error,
      }),
    )
  }
}

function* disableMosSaga(
  action: RundownActionMap<RundownActionType.DISABLE_MOS_REQUEST>,
): Generator {
  try {
    const result = yield call(api.disableMos, action.rundownId)
    const devicesNames = result as string[]
    yield put(disableMosSuccess(devicesNames as string[]))

    const count = devicesNames.length
    const devices = devicesNames.join(', ')

    yield put(
      notifySuccess(
        count > 0
          ? {
              message: i18n.t('words:success'),
              description: i18n.t('rundown:disableMosSuccess', { count, devices }),
            }
          : {
              message: i18n.t('words:info'),
              description: i18n.t('rundown:disableMosSuccessEmpty'),
            },
      ),
    )
  } catch (error: any) {
    yield put(disableMosFailure(error))
    yield put(
      notifyWarning({
        message: i18n.t('error:operation'),
        description: i18n.t('error:actionFailed'),
        error,
      }),
    )
  }
}

function* handleMosAction(
  action: RundownActionMap<RundownActionType.RUN_MOS_ACTION_REQUEST>,
  apiCall: typeof api.mosReplace | typeof api.notifyMosCreate | typeof api.notifyMosReady,
  successMessage: TranslationDictionary,
  emptyMessage: TranslationDictionary,
): Generator {
  try {
    const result = yield call(apiCall, action.rundownId, action.deviceId)
    const devicesNames = result as string[]
    yield put(runMosActionSuccess(devicesNames as string[]))

    const count = devicesNames.length
    const devices = devicesNames.join(', ')

    yield put(
      notifySuccess(
        count > 0
          ? {
              message: i18n.t('words:success'),
              description: i18n.t(successMessage, { count, devices }),
            }
          : { message: i18n.t('words:info'), description: i18n.t(emptyMessage) },
      ),
    )
  } catch (error: any) {
    yield put(runMosActionFailure(error))
    yield put(
      notifyWarning({
        message: i18n.t('error:operation'),
        description: i18n.t('error:actionFailed'),
        error,
      }),
    )
  }
}

function* runMosActionSaga(
  action: RundownActionMap<RundownActionType.RUN_MOS_ACTION_REQUEST>,
): Generator {
  switch (action.action) {
    case 'create':
      yield handleMosAction(
        action,
        api.notifyMosCreate,
        'rundown:notifyMosSuccess',
        'rundown:notifyMosEmpty',
      )
      break
    case 'ready':
      yield handleMosAction(
        action,
        api.notifyMosReady,
        'rundown:mosReplaceSuccess',
        'rundown:notifyMosEmpty',
      )
      break
    case 'replace':
      yield handleMosAction(
        action,
        api.mosReplace,
        'rundown:notifyMosSuccess',
        'rundown:notifyMosEmpty',
      )
      break
  }
}

function* loadTpContentSaga(
  action: RundownActionMap<RundownActionType.LOAD_TP_CONTENT_REQUEST>,
): Generator {
  try {
    const stories = yield call(api.loadStories, action.id, false)
    yield put(loadTpContentSuccess(stories as Story[]))
  } catch (error: any) {
    yield put(loadTpContentFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

//
//  Block
//

function* createBlockSaga(
  action: RundownActionMap<RundownActionType.CREATE_BLOCK_REQUEST>,
): Generator {
  try {
    const block = yield call(api.createBlock, action.rundownId)
    yield put(createBlockSuccess(block as Block))
  } catch (error: any) {
    yield put(createBlockFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* patchBlockSaga(
  action: RundownActionMap<RundownActionType.PATCH_BLOCK_REQUEST>,
): Generator {
  try {
    yield call(api.patchBlock, action.blockId, action.field, action.newValue)
    yield put(patchBlockSuccess())
  } catch (error: any) {
    yield put(
      patchBlockFailure(error, action.blockId, action.field, action.newValue, action.oldValue),
    )
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* toggleCurrentStatusSaga(
  action: RundownActionMap<RundownActionType.TOGGLE_CURRENT_STATUS_REQUEST>,
): Generator {
  try {
    yield call(api.toggleCurrentStatus, action.id)
    yield put(toggleCurrentStatusSuccess())
  } catch (error: any) {
    yield put(toggleCurrentStatusFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* toggleBlockStatusSaga(
  action: RundownActionMap<RundownActionType.TOGGLE_BLOCK_STATUS_REQUEST>,
): Generator {
  try {
    yield call(api.toggleBlockStatus, action.id)
    yield put(toggleBlockStatusSuccess())
  } catch (error: any) {
    yield put(toggleBlockStatusFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeBlockSaga(
  action: RundownActionMap<RundownActionType.REMOVE_BLOCK_REQUEST>,
): Generator {
  try {
    yield call(api.removeBlock, action.blockId)
    yield put(removeBlockSuccess(action.blockId))
  } catch (error: any) {
    yield put(removeBlockFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

//
//  Story
//

function* createStorySaga(
  action: RundownActionMap<RundownActionType.CREATE_STORY_REQUEST>,
): Generator {
  try {
    const story = yield call(api.createStory, action.blockId)
    yield put(createStorySuccess(story as Story))
  } catch (error: any) {
    yield put(createStoryFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* createStoryAfterSaga(
  action: RundownActionMap<RundownActionType.CREATE_STORY_AFTER_REQUEST>,
): Generator {
  try {
    const story = yield call(api.createStoryAfter, action.afterStory)
    yield put(createStoryAfterSuccess(story as Story))
  } catch (error: any) {
    yield put(createStoryAfterFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* moveStoriesSaga(
  action: RundownActionMap<RundownActionType.MOVE_STORIES_REQUEST>,
): Generator {
  try {
    yield call(api.moveStories, action.targetBlockId, action.storiesIds, action.targetStoryId)
    yield put(moveStoriesSuccess())
  } catch (error: any) {
    yield put(
      moveStoriesFailure(error, action.storiesIds, action.targetBlockId, action.targetStoryId),
    )
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )

    // Para desfazer a mudança otimista, como pode ter vindo laudas de espelhos diferentes (recortar),
    // é preciso recarregar os espelhos.
    const tabs = (yield select(rundownTabsList)) as RundownStateTab[]
    for (const tab of tabs) {
      if (tab.type === 'rundown') {
        yield loadRundown(tab.uuid, tab.date, tab.programId!)
      }
    }
  }
}

function* copyPasteSaga(
  action: RundownActionMap<RundownActionType.COPY_PASTE_STORIES_REQUEST>,
): Generator {
  try {
    yield call(
      api.copyStoriesToBlock,
      action.targetBlockId,
      action.storiesIds,
      action.targetStoryId,
    )
    yield put(copyPasteStoriesSuccess(action.targetBlockId, action.targetStoryId))
  } catch (error: any) {
    yield put(copyPasteStoriesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* pasteSaga(action: RundownActionMap<RundownActionType.PASTE>): Generator {
  const { type, targets } = (yield select(operationData)) as RundownOperationState

  if (targets.length === 0) {
    return
  }
  if (action.targetStoryId && targets.includes(action.targetStoryId)) {
    return
  }
  if (type === 'cut') {
    yield put(moveStories(targets, action.targetBlockId, action.targetStoryId))
  }
  if (type === 'copy') {
    yield put(copyPasteStories(targets, action.targetBlockId, action.targetStoryId))
  }
}

function* repaginateSaga(
  action: RundownActionMap<RundownActionType.REPAGINATE_REQUEST>,
): Generator {
  try {
    yield call(api.repaginateStories, action.rundownId)
    yield put(repaginateSuccess())
  } catch (error: any) {
    yield put(repaginateFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

//
// approverundown
//

function* approveRundownSaga(
  action: RundownActionMap<RundownActionType.APPROVE_RUNDOWN_REQUEST>,
): Generator {
  try {
    yield call(api.approveRundown, action.rundownId, action.approve)
    yield put(approveRundownSuccess())
  } catch (error: any) {
    yield put(approveRundownFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

//
// approverundownblock
//

function* approveRundownBlockSaga(
  action: RundownActionMap<RundownActionType.APPROVE_RUNDOWN_BLOCK_REQUEST>,
): Generator {
  try {
    yield call(api.approveRundownBlock, action.blockId, action.approve)
    yield put(approveRundownBlockSuccess())
  } catch (error: any) {
    yield put(approveRundownBlockFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

//
// sendRundownCredits
//

function* sendRundownCreditsSaga(
  action: RundownActionMap<RundownActionType.SEND_CREDITS_REQUEST>,
): Generator {
  try {
    const success = yield call(api.sendCredits, action.rundownId)
    yield put(sendRundownCreditsSuccess(success as boolean))

    if (success) {
      yield put(
        notifyInfo({
          message: i18n.t('words:success'),
          description: i18n.t('rundown:credits_sendSuccess'),
        }),
      )
    } else {
      yield put(
        notifyInfo({
          message: i18n.t('words:warning'),
          description: i18n.t('rundown:credits_empty'),
        }),
      )
    }
  } catch (error: any) {
    yield put(sendRundownCreditsFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('rundown:credits_sendFailure'),
        error,
      }),
    )
  }
}

//
// Config
//

function* loadConfigSaga(): Generator {
  try {
    const config = yield call(api.loadConfig)
    yield put(loadConfigSuccess(config as RundownConfig))
  } catch (error: any) {
    yield put(loadConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createConfigSaga(
  action: RundownActionMap<RundownActionType.CREATE_CONFIG_REQUEST>,
): Generator {
  try {
    const config = yield call(api.createConfig, action.config)
    yield put(createConfigSuccess(config as RundownConfig))
  } catch (error: any) {
    yield put(createConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateConfigSaga(
  action: RundownActionMap<RundownActionType.UPDATE_CONFIG_REQUEST>,
): Generator {
  try {
    const config = yield call(api.updateConfig, action.config)
    yield put(updateConfigSuccess(config as RundownConfig))
  } catch (error: any) {
    yield put(updateConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeEvery(RundownActionType.LOAD_REQUEST, loadRundownSaga)
  },
  function* () {
    yield takeEvery(RundownActionType.CREATE_REQUEST, createRundownSaga)
  },
  function* () {
    yield takeEvery(RundownActionType.PATCH_REQUEST, patchRundownSaga)
  },
  function* () {
    yield takeEvery(RundownActionType.CREATE_BLOCK_REQUEST, createBlockSaga)
  },
  function* () {
    yield takeEvery(RundownActionType.PATCH_BLOCK_REQUEST, patchBlockSaga)
  },
  function* () {
    yield takeLeading(RundownActionType.TOGGLE_CURRENT_STATUS_REQUEST, toggleCurrentStatusSaga)
  },
  function* () {
    yield takeLeading(RundownActionType.TOGGLE_BLOCK_STATUS_REQUEST, toggleBlockStatusSaga)
  },
  function* () {
    yield takeEvery(RundownActionType.REMOVE_BLOCK_REQUEST, removeBlockSaga)
  },
  function* () {
    yield takeEvery(RundownActionType.CREATE_STORY_REQUEST, createStorySaga)
  },
  function* () {
    yield takeEvery(RundownActionType.CREATE_STORY_AFTER_REQUEST, createStoryAfterSaga)
  },
  function* () {
    yield takeEvery(RundownActionType.MOVE_STORIES_REQUEST, moveStoriesSaga)
  },
  function* () {
    yield takeLatest(RundownActionType.LOAD_CONFIG_REQUEST, loadConfigSaga)
  },
  function* () {
    yield takeLatest(RundownActionType.CREATE_CONFIG_REQUEST, createConfigSaga)
  },
  function* () {
    yield takeLatest(RundownActionType.UPDATE_CONFIG_REQUEST, updateConfigSaga)
  },
  function* () {
    yield takeLatest(RundownActionType.PASTE, pasteSaga)
  },
  function* () {
    yield takeLatest(RundownActionType.COPY_PASTE_STORIES_REQUEST, copyPasteSaga)
  },
  function* () {
    yield takeLatest(RundownActionType.REPAGINATE_REQUEST, repaginateSaga)
  },
  function* () {
    yield takeLatest(RundownActionType.APPROVE_RUNDOWN_REQUEST, approveRundownSaga)
  },
  function* () {
    yield takeLatest(RundownActionType.APPROVE_RUNDOWN_BLOCK_REQUEST, approveRundownBlockSaga)
  },
  function* () {
    yield takeLatest(RundownActionType.SEND_CREDITS_REQUEST, sendRundownCreditsSaga)
  },
  function* () {
    yield takeLatest(RundownActionType.ENABLE_MOS_REQUEST, enableMosSaga)
  },
  function* () {
    yield takeLatest(RundownActionType.DISABLE_MOS_REQUEST, disableMosSaga)
  },
  function* () {
    yield takeLatest(RundownActionType.RUN_MOS_ACTION_REQUEST, runMosActionSaga)
  },
  function* () {
    yield takeLatest(RundownActionType.LOAD_TP_CONTENT_REQUEST, loadTpContentSaga)
  },
])
