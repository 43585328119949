import { Reducer } from 'redux'

import { AgendaContact, Contact } from '@anews/types'

import { ActionType, ContactAction } from '../actions/contact-actions'

export interface ContactsPageState {
  data: AgendaContact[]
  number: number
  size: number
  total: number
  filter?: string
  loading: boolean
}

export interface ContactsState {
  page: ContactsPageState
  editing?: Contact
  viewing?: AgendaContact
  saving: boolean
  disabling: boolean
}

export const defaultState: ContactsState = {
  page: {
    data: [],
    number: 0,
    size: 0,
    total: 0,
    filter: '',
    loading: true,
  },
  editing: undefined,
  viewing: undefined,
  saving: false,
  disabling: false,
}

function pageReducer(page: ContactsPageState, action: ContactAction): ContactsPageState {
  switch (action.type) {
    case ActionType.FILTER_REQUEST:
      return { ...page, loading: true, filter: action.filter }

    case ActionType.FILTER_FAILURE:
      return { ...page, loading: false }

    case ActionType.FILTER_SUCCESS:
      return {
        ...page,
        data: action.result.data,
        total: action.result.total,
        number: action.result.page,
        size: action.result.size,
        loading: false,
      }

    // case ActionType.CREATE_SUCCESS:
    //   if (action.hidden) {
    //     return page
    //   }
    //   return { ...page, data: [action.contact as AgendaContact, ...page.data] }

    case ActionType.UPDATE_SUCCESS:
      if (action.hidden) {
        return page
      }
      return {
        ...page,
        data: page.data.map(c =>
          c.id === action.contact.id ? (action.contact as AgendaContact) : c,
        ),
      }

    case ActionType.REMOVE_SUCCESS:
      return { ...page, data: page.data.filter(u => !action.ids.includes(u.id)) }

    default:
      return page
  }
}

const contactsReducer: Reducer<ContactsState, ContactAction> = (
  state = defaultState,
  action,
): ContactsState => {
  switch (action.type) {
    case ActionType.FILTER_REQUEST:
    case ActionType.FILTER_FAILURE:
    case ActionType.FILTER_SUCCESS:
      return { ...state, page: pageReducer(state.page, action) }

    case ActionType.UNLOAD:
      return { ...state, editing: undefined, viewing: undefined }

    case ActionType.NEW:
      return { ...state, editing: action.contact }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...state, editing: action.contact, saving: true }

    case ActionType.LOAD_SUCCESS:
      if (action.edit) {
        return { ...state, editing: action.contact }
      }
      return { ...state, viewing: action.contact as AgendaContact }

    case ActionType.REMOVE_REQUEST:
      return { ...state, disabling: true }

    case ActionType.REMOVE_FAILURE:
      return { ...state, disabling: false }

    case ActionType.CREATE_FAILURE:
    case ActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
    case ActionType.REMOVE_SUCCESS:
      return {
        ...state,
        editing: undefined,
        page: pageReducer(state.page, action),
        disabling: false,
        saving: false,
      }

    default:
      return state
  }
}

export default contactsReducer
