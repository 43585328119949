/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import React, { memo, useCallback, useMemo, useState } from 'react'
import parseHtml from 'react-html-parser'

import Button from 'antd/es/button'
import Col from 'antd/es/col'
import Divider from 'antd/es/divider'
import List from 'antd/es/list'
import PageHeader from 'antd/es/page-header'
import Row from 'antd/es/row'
import Tooltip from 'antd/es/tooltip'
import message from 'antd/es/message'

import { CheckCircleTwoTone, CopyOutlined, DiffOutlined } from '@ant-design/icons'

import {
  APPEARANCE,
  ART,
  INTERVIEW,
  OFF,
  SOUND_UP,
  Reportage,
  ReportageSection,
  ReportageRevision,
} from '@anews/types'

import {
  mapJoin,
  displayDate,
  isNotBlank,
  formatIsoDuration,
  highlight,
  richToPlain,
  toggleRichFormat,
} from '@anews/utils'

import { reportageApi } from '@anews/api'

import { SelectorMap } from '../../../redux/selectors/helpers'
import { CarouselModal, VGroup, ViewerItem } from '../../global'
import { useTranslation } from '../../../i18n'
import theme from '../../../theme'

import RevisionCard from '../revision/RevisionCard'

import RichTextViewer from '../../global/RichText/RichTextViewer'

import reportageToClipboard from './reportageToClipboard'

// O texto da sonora é uma citação do que o entrevistado falou, então coloca em aspas
/* function quotes(text?: string) {
  if (isBlank(text)) {
    return text
  }
  let result = text!.trim()
  if (!result.startsWith('"')) {
    result = `"${result}`
  }
  if (result.charAt(result.length - 1) !== '"') {
    result = `${result}"`
  }
  return result
} */

// Sonora tem os campos invertidos
function sectionContent(section: ReportageSection) {
  return section.type !== INTERVIEW ? section.content : section.observation
}

// Sonora tem os campos invertidos
function sectionObservation(section: ReportageSection) {
  return section.type !== INTERVIEW
    ? section.observation
    : toggleRichFormat(section.content, 'quotes')
}

// Arte e Sobe som não tem contagem de tempo
function sectionDuration(section: ReportageSection) {
  switch (section.type) {
    case ART:
    case SOUND_UP:
      return undefined
    default:
      return (
        <ViewerItem.Header
          css={css`
            text-align: right;
          `}
        >
          {formatIsoDuration(section.duration || 'PT0S', 'mm:ss')}
        </ViewerItem.Header>
      )
  }
}

interface Props {
  reportage?: Reportage
  editable: boolean
  onEdit?: (id: number) => void
  onClose?: () => void
  classifications: SelectorMap<string>
  companies: SelectorMap<string>
  editorials: SelectorMap<string>
  programs: SelectorMap<string>
  users: SelectorMap<string>
  vehicles: SelectorMap<string>
}

function ReportageViewer({
  reportage,
  classifications,
  companies,
  editorials,
  programs,
  users,
  vehicles,
  editable,
  onEdit,
  onClose,
}: Props) {
  const { t } = useTranslation()

  const getText = useCallback(
    e => {
      if (e.detail === 1 && reportage) {
        reportageToClipboard(reportage, {
          programs,
          users,
          companies,
          editorials,
          vehicles,
          classifications,
        })
      }
    },
    [reportage, programs, users, companies, editorials, vehicles, classifications],
  )

  //
  // Versões
  //

  const [versionsVisible, setVersionsVisible] = useState(false)
  const [reportageVersions, setReportageVersions] = useState<ReportageRevision[]>([])

  const versionsCards = useMemo(
    () =>
      reportageVersions.map(version => (
        <RevisionCard
          key={version.id}
          date={version.revisionDate}
          ip={version.revisorIP}
          nickname={version.revisorNickname}
          slug={version.slug}
          content={version}
        />
      )),
    [reportageVersions],
  )

  const versionsHandler = useCallback(async () => {
    if (reportage) {
      try {
        const result = await reportageApi.loadChangesHistory(reportage.id)
        setReportageVersions(result)
        setVersionsVisible(true)
      } catch (error) {
        console.error(error)
        message.error(t('error:loadFailed'))
      }
    }
  }, [reportage, t])

  if (!reportage) {
    return null
  }

  const actions = [
    <Tooltip key="copy" placement="bottom" title={t('phrases:copyToClipboard')}>
      <Button onClick={getText} icon={<CopyOutlined />} />
    </Tooltip>,
  ]

  actions.push(
    <Tooltip key="version" placement="bottom" title={t('words:versions')}>
      <Button onClick={versionsHandler} icon={<DiffOutlined />} />
    </Tooltip>,
  )

  editable &&
    actions.push(
      <Button key={1} onClick={() => onEdit && onEdit(reportage.id)}>
        {t('words:edit')}
      </Button>,
    )

  onClose &&
    actions.push(
      <Button key={2} onClick={onClose} type="primary">
        {t('words:close')}
      </Button>,
    )

  let appearanceCount = 0
  let artCount = 0
  let interviewCount = 0
  let offCount = 0
  let soundUpCount = 0

  const sectionName = (section: ReportageSection) => {
    const labels = []

    switch (section.type) {
      case APPEARANCE:
        appearanceCount += 1

        if (reportage.tv || !reportage.radio) {
          labels.push(t('reportage:appearance'))
        }
        if (reportage.radio) {
          labels.push(t('reportage:loc'))
        }

        return [labels.join('/'), ' ', appearanceCount > 9 ? '' : '0', appearanceCount].join('')
      case ART:
        artCount += 1
        return [t('reportage:art'), ' ', artCount > 9 ? '' : '0', artCount].join('')
      case INTERVIEW:
        interviewCount += 1
        return [t('reportage:interview'), ' ', interviewCount > 9 ? '' : '0', interviewCount].join(
          '',
        )
      case OFF:
        offCount += 1
        return [t('reportage:off'), ' ', offCount > 9 ? '' : '0', offCount].join('')
      case SOUND_UP:
        soundUpCount += 1
        return [t('reportage:soundup'), ' ', soundUpCount > 9 ? '' : '0', soundUpCount].join('')
      default:
        return ''
    }
  }

  const observationLabel = (section: ReportageSection) => {
    switch (section.type) {
      case APPEARANCE:
        return t('words:local')
      case ART:
        return t('reportage:data')
      case INTERVIEW:
        return undefined
      default:
        return t('reportage:indications')
    }
  }

  const date = displayDate(reportage.date)
  const classification = classifications[reportage.classificationId || 0]
  const company = companies[reportage.branchId || 0]
  const editor = users[reportage.editorId || 0]
  const reporter = users[reportage.reporterId!]

  const editorialsNames = mapJoin(editorials, reportage.editorialsIds)
  const programsNames = mapJoin(programs, reportage.programsIds) || t('program:drawer')
  const vehiclesNames = mapJoin(vehicles, reportage.vehiclesIds)

  return (
    <PageHeader
      title={
        <Tooltip title={highlight(reportage.slug)} placement="left">
          {highlight(reportage.slug)}
        </Tooltip>
      }
      extra={actions}
    >
      {/* Data e Praça */}
      <Row>
        <Col span={12}>
          <ViewerItem label={t('words:date')}>{date}</ViewerItem>
        </Col>
        {company && (
          <Col span={12}>
            <ViewerItem label={t('words:branch')}>{company}</ViewerItem>
          </Col>
        )}
      </Row>

      {/* Repórter & Editor */}
      <Row>
        {reporter && (
          <Col span={12}>
            <ViewerItem label={t('words:reporter')}>{reporter}</ViewerItem>
          </Col>
        )}
        {editor && (
          <Col span={12}>
            <ViewerItem label={t('words:editor')}>{editor}</ViewerItem>
          </Col>
        )}
      </Row>

      {/* Programas */}
      <ViewerItem label={t('words:programs')}>{programsNames}</ViewerItem>

      {/* Editorias */}
      <ViewerItem label={t('words:editorials')}>{editorialsNames}</ViewerItem>

      {/* Veículos */}
      <ViewerItem label={t('words:vehicles')}>{vehiclesNames}</ViewerItem>

      {/* Classificação e Imagens */}
      <Row>
        {classification && (
          <Col span={12}>
            <ViewerItem label={t('words:classification')}>{classification}</ViewerItem>
          </Col>
        )}
        {isNotBlank(reportage.cameraman) && (
          <Col span={12}>
            <ViewerItem label={t('words:cameraman')}>{reportage.cameraman}</ViewerItem>
          </Col>
        )}
      </Row>

      {reportage.cgs && reportage.cgs.length > 0 && (
        <ViewerItem label={t('reportage:cgs')}>
          <List>
            {reportage.cgs
              .filter(cg => !!cg.ciiData)
              .map(({ uuid, ciiData }) => (
                <List.Item key={uuid}>
                  <VGroup>
                    <b>{ciiData?.templateName}</b>
                    <VGroup>
                      {ciiData?.fields.map(field => (
                        <div key={field.uuid}>
                          <span>{field.name}: </span>
                          <span>{field.value}</span>
                        </div>
                      ))}
                    </VGroup>
                  </VGroup>
                </List.Item>
              ))}
            {reportage.cgs
              .filter(cg => !!cg.mosData)
              .map(({ uuid, mosData }) => (
                <List.Item key={uuid}>
                  <VGroup>
                    <div>
                      <b>{mosData?.slug}</b>
                    </div>
                    {mosData?.mosAbstract && parseHtml(mosData?.mosAbstract)}
                  </VGroup>
                </List.Item>
              ))}
          </List>
        </ViewerItem>
      )}

      {/* Lead */}
      {isNotBlank(reportage.lead) && (
        <ViewerItem label={t('reportage:lead')}>{highlight(reportage.lead)}</ViewerItem>
      )}

      {/* Texto */}
      {isNotBlank(richToPlain(reportage.text)) && (
        <ViewerItem label={t('reportage:text')}>
          <RichTextViewer content={reportage.text} />
        </ViewerItem>
      )}

      {/* Teaser */}
      {isNotBlank(richToPlain(reportage.teaser)) && (
        <ViewerItem label={t('reportage:teaser')}>
          <RichTextViewer content={reportage.teaser} />
        </ViewerItem>
      )}

      {/* Sugestão de cabeça */}
      {isNotBlank(richToPlain(reportage.headingSuggestion)) && (
        <ViewerItem label={t('reportage:heading')}>
          <RichTextViewer content={reportage.headingSuggestion} />
        </ViewerItem>
      )}

      {/* Sugestão de nota pé */}
      {isNotBlank(richToPlain(reportage.noteSuggestion)) && (
        <ViewerItem label={t('reportage:footnote')}>
          <RichTextViewer content={reportage.noteSuggestion} />
        </ViewerItem>
      )}

      {/* Informações */}
      {isNotBlank(richToPlain(reportage.information)) && (
        <ViewerItem label={t('reportage:info')}>
          <RichTextViewer content={reportage.information} />
        </ViewerItem>
      )}

      {/* Seções */}
      {(reportage?.sections || []).map((section: ReportageSection) => {
        const content = sectionContent(section)
        const observation = sectionObservation(section)

        if (!content && !observation) {
          return null
        }

        const obsLabel = observationLabel(section)
        const duration = sectionDuration(section)

        return (
          <div key={section.id}>
            <Divider />
            {!duration && <ViewerItem.Header>{sectionName(section)}</ViewerItem.Header>}
            {duration && (
              <Row>
                <Col span={18}>
                  <ViewerItem.Header>
                    {sectionName(section)}{' '}
                    {section.approved && <CheckCircleTwoTone twoToneColor={theme.colors.green} />}
                  </ViewerItem.Header>
                </Col>
                <Col span={6}>{duration}</Col>
              </Row>
            )}

            <ViewerItem>
              <RichTextViewer content={content} />
            </ViewerItem>

            <ViewerItem label={obsLabel}>
              <RichTextViewer content={observation} />
            </ViewerItem>
          </div>
        )
      })}

      {/* Versões */}
      <CarouselModal
        content={versionsCards}
        visible={versionsVisible}
        title={t('words:versions')}
        onClose={() => setVersionsVisible(false)}
      />
    </PageHeader>
  )
}

export default memo(ReportageViewer)
