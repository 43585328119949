import React, {
  PureComponent,
  SyntheticEvent,
  KeyboardEvent,
  MouseEvent,
  ChangeEvent,
  CSSProperties,
} from 'react'
import styled from '@emotion/styled'

import Input from 'antd/es/input'
import Select from 'antd/es/select'

import DurationInput from '../DurationInput'

import SelectWithSearch from '../SelectWithSearch'

import { ColumnProps } from './index'

const KEY_ENTER = 13
const KEY_ESC = 27

const StyledInput = styled(Input)`
  height: 20px;
  margin: 0 -4px;
  padding: 0 4px;
  border: none;
  border-radius: 0;
`
const StyledDurationInput = styled(DurationInput)`
  height: 20px;
  margin: 0 -4px;
  padding: 0 4px;
  border: none;
  border-radius: 0;
`
/* const StyledSelect = styled(Select)`
  margin-left: -4px;
  .ant-select-selection {
    height: 20px;
    margin: 0;
    padding: 0 0 0 4px;
    border: none;
    border-radius: 0;

    .ant-select-selection__rendered {
      margin-left: 0;
      margin-right: 8px;
      line-height: 20px;
    }
  }
` */

interface Props {
  column: ColumnProps
  index: number
  recordKey: number
  recordValue: any
  isSelected?: boolean
  disabled?: boolean
}

interface State {
  editing: boolean
  initial: any
  value: any
}

class Cell extends PureComponent<Props, State> {
  private editTimeout?: number

  private field?: Input | null

  constructor(props: Props) {
    super(props)

    this.state = {
      editing: false,
      initial: undefined,
      value: undefined,
    }
  }

  componentDidUpdate() {
    const { editing } = this.state
    const { isSelected } = this.props
    if (!isSelected && editing) {
      this.save()
    }
  }

  stopPropagation = (event?: SyntheticEvent) => {
    if (event) event.stopPropagation()
  }

  toggleEdit = (event?: SyntheticEvent) => {
    const {
      column: { editor },
      recordValue,
    } = this.props
    const { editing } = this.state

    if (typeof editor === 'object') {
      this.stopPropagation(event)

      const newEditing = !editing

      this.setState({ editing: newEditing, initial: recordValue, value: recordValue }, () => {
        if (newEditing && this.field) {
          this.field.focus()
        }
      })
    }
  }

  toggleEditOnEnter = (event: KeyboardEvent) => {
    if (event.keyCode === KEY_ENTER) {
      this.toggleEdit(event)
    }
  }

  cancelEditOnEsc = (event: KeyboardEvent) => {
    const { editing } = this.state
    if (event.keyCode === KEY_ESC && editing) {
      this.toggleEdit(event)
    }

    event.stopPropagation()
  }

  updateValue = (arg: ChangeEvent | any) => {
    if (arg && arg.target) {
      // input
      this.setState({ value: arg.target.value })
    } else {
      const {
        column: { editor },
      } = this.props
      const { type } = editor!

      if (type === 'select') {
        this.setState({ value: arg }, () => this.save())
      } else {
        this.setState({ value: arg })
      }
    }
  }

  save = () => {
    const { initial, value } = this.state

    const {
      column: { dataIndex, editor },
      recordKey,
    } = this.props

    const { onChange, transformer, validator } = editor!

    if (initial !== value && (!validator || validator(value))) {
      if (transformer) {
        onChange(recordKey, dataIndex, transformer(value), initial)
      } else {
        onChange(recordKey, dataIndex, value, initial)
      }
    }
    this.toggleEdit()
  }

  requestEdit = (event: MouseEvent) => {
    if (event.ctrlKey || event.shiftKey) {
      return
    }
    const { editing } = this.state
    const {
      column: { editor },
    } = this.props

    if (!editor || editor.disabled || editing) {
      return
    }

    /*
     * Controle para não ativar a edição ao dar um duplo clique na linha.
     * Edita somente com um clique único.
     */
    if (this.editTimeout) {
      this.cancelEditRequest()
    } else {
      // Campo com edição não vai propagar o clique, assim não vai selecionar a linha
      // this.stopPropagation(event)

      const self = this
      this.editTimeout = window.setTimeout(() => {
        self.cancelEditRequest()
        self.toggleEdit()
      }, 250)
    }
  }

  cancelEditRequest = () => {
    const { editing } = this.state
    if (!editing) {
      window.clearTimeout(this.editTimeout)
      this.editTimeout = undefined
    }
  }

  render() {
    const {
      column: { width, editor, onCell, render, align },
      index,
      recordKey,
      recordValue,
    } = this.props

    const { editing, value } = this.state
    const extraProps = onCell ? onCell(recordValue, recordKey, index) : {}

    let content

    if (!editor || editor.disabled || !editing) {
      content = typeof render === 'function' ? render(recordValue, recordKey, index) : recordValue
    } else if (editor) {
      const { type, durationFormat, maxLength, values, valueKey, valueLabel, minWidth } = editor
      if (type === 'input') {
        content = (
          <StyledInput
            size="small"
            className={editor.className}
            value={value}
            ref={node => {
              this.field = node
            }}
            style={{ minWidth }}
            maxLength={maxLength}
            onChange={this.updateValue}
            onClick={this.stopPropagation}
            // onDoubleClick={this.stopPropagation}
            onPressEnter={this.save}
            onKeyUp={this.cancelEditOnEsc}
            onBlur={this.save}
          />
        )
      }
      if (type === 'duration') {
        content = (
          <StyledDurationInput
            size="small"
            format={durationFormat!}
            value={value}
            ref={(node: Input) => {
              this.field = node
            }}
            onChange={this.updateValue}
            onClick={this.stopPropagation}
            // onDoubleClick={this.stopPropagation}
            onPressEnter={this.save}
            onKeyUp={this.cancelEditOnEsc}
            onBlur={this.save}
          />
        )
      }
      if (type === 'select') {
        content = (
          <SelectWithSearch
            size="small"
            value={value}
            onChange={this.updateValue}
            // onClick={this.stopPropagation}
            // onDoubleClick={this.stopPropagation}
            onKeyUp={this.cancelEditOnEsc}
            onBlur={this.save}
            style={{
              minWidth: '100%',
              maxWidth: typeof width === 'number' ? width : undefined,
              width: '100%',
              marginLeft: '-4px',
            }}
            dropdownStyle={{ minWidth: typeof width === 'number' ? width * 1.5 : 192 }}
            dropdownMatchSelectWidth
            defaultOpen
            allowClear
            autoFocus
          >
            {(values || []).map((v: any) => (
              <Select.Option key={v[valueKey!]} value={v[valueKey!]}>
                {v[valueLabel!]}
              </Select.Option>
            ))}
          </SelectWithSearch>
        )
      }
    }

    const { style = {}, ...rest } = extraProps
    const finalStyle: CSSProperties = { ...style, textAlign: align }

    if (width) {
      finalStyle.width = width
      finalStyle.minWidth = width
      finalStyle.maxWidth = width
    }
    if (editing) {
      finalStyle.padding = '0 0 0 8px'
    }

    const { disabled } = this.props

    return (
      <td
        title={typeof content === 'string' ? content : undefined}
        // onClick={this.toggleEdit}
        onClick={!disabled ? this.requestEdit : undefined}
        onDoubleClick={!disabled ? this.cancelEditRequest : undefined}
        onKeyPress={!disabled ? this.toggleEditOnEnter : undefined}
        role="presentation"
        style={finalStyle}
        {...rest}
      >
        {content}
      </td>
    )
  }
}

export default Cell
