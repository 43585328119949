import { get, query } from './client';
var endpoint = '/api/mos';
var mosApi = {
    encodings: function () {
        return get(endpoint + "/encodings");
    },
    plugins: function () {
        return get(endpoint + "/plugins");
    },
    revisions: function () {
        return get(endpoint + "/revisions");
    },
    test: function (data) {
        return get(query(endpoint + "/test", data));
    },
    devicesByProfile: function (profile) {
        return get(query(endpoint + "/devices", { profile: profile }));
    },
};
export default mosApi;
