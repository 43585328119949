import { Reducer } from 'redux'

import produce from 'immer'

import {
  Company,
  DocumentConfig,
  GuidelineConfig,
  Program,
  Classification,
  User,
  Vehicle,
  Editorial,
  Team,
  DocumentType,
  ReportageConfig,
  StoryConfig,
  RundownConfig,
} from '@anews/types'

import { CompanyActionType, CompanyAction } from '../actions/company-actions'

export interface CompaniesListState {
  data: Company[]
  loading: boolean
}

export interface RemoteData<T> {
  data: T[]
  loading: boolean
}

export interface RemoteListMap<T> {
  [companyId: number]: RemoteData<T>
}

export interface RemoteConfigData<T> {
  [companyId: number]: { data: T | undefined; loading: boolean }
}

export interface CompaniesState {
  list: CompaniesListState
  programs: RemoteListMap<Program>
  classifications: RemoteListMap<Classification>
  users: RemoteListMap<User>
  editors: RemoteListMap<User>
  producers: RemoteListMap<User>
  reporters: RemoteListMap<User>
  vehicles: RemoteListMap<Vehicle>
  documentConfigs: RemoteConfigData<DocumentConfig>
  guidelineConfigs: RemoteConfigData<GuidelineConfig>
  reportageConfigs: RemoteConfigData<ReportageConfig>
  storyConfigs: RemoteConfigData<StoryConfig>
  rundownConfigs: RemoteConfigData<RundownConfig>
  remoteCompanies: RemoteListMap<Company>
  editorials: RemoteListMap<Editorial>
  teams: RemoteListMap<Team>
  types: RemoteListMap<DocumentType>
}

export const defaultState: CompaniesState = {
  list: {
    data: [],
    loading: true,
  },
  programs: {},
  classifications: {},
  users: {},
  editors: {},
  producers: {},
  reporters: {},
  vehicles: {},
  documentConfigs: {},
  guidelineConfigs: {},
  reportageConfigs: {},
  storyConfigs: {},
  rundownConfigs: {},
  remoteCompanies: {},
  editorials: {},
  teams: {},
  types: {},
}

function listReducer(list: CompaniesListState, action: CompanyAction): CompaniesListState {
  switch (action.type) {
    case CompanyActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case CompanyActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case CompanyActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.companies,
        loading: false,
      }

    default:
      return list
  }
}

const companiesReducer: Reducer<CompaniesState, CompanyAction> = (
  state = defaultState,
  action,
): CompaniesState => {
  switch (action.type) {
    case CompanyActionType.LIST_REQUEST:
    case CompanyActionType.LIST_FAILURE:
    case CompanyActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    // SUCCESS CASES
    case CompanyActionType.LIST_PROGRAMS_SUCCESS:
      return produce(state, draft => {
        draft.programs[action.companyId] = { data: action.programs, loading: false }
      })
    case CompanyActionType.LIST_CLASSIFICATIONS_SUCCESS:
      return produce(state, draft => {
        draft.classifications[action.companyId] = { data: action.classifications, loading: false }
      })
    case CompanyActionType.LIST_USERS_SUCCESS:
      return produce(state, draft => {
        draft.users[action.companyId] = { data: action.users, loading: false }
      })
    case CompanyActionType.LIST_EDITORS_SUCCESS:
      return produce(state, draft => {
        draft.editors[action.companyId] = { data: action.editors, loading: false }
      })
    case CompanyActionType.LIST_PRODUCERS_SUCCESS:
      return produce(state, draft => {
        draft.producers[action.companyId] = { data: action.producers, loading: false }
      })
    case CompanyActionType.LIST_REPORTERS_SUCCESS:
      return produce(state, draft => {
        draft.reporters[action.companyId] = { data: action.reporters, loading: false }
      })
    case CompanyActionType.LIST_VEHICLES_SUCCESS:
      return produce(state, draft => {
        draft.vehicles[action.companyId] = { data: action.vehicles, loading: false }
      })
    case CompanyActionType.LIST_DOCUMENT_CONFIG_SUCCESS:
      return produce(state, draft => {
        draft.documentConfigs[action.companyId] = { data: action.documentConfig, loading: false }
      })
    case CompanyActionType.LIST_GUIDELINE_CONFIG_SUCCESS:
      return produce(state, draft => {
        draft.guidelineConfigs[action.companyId] = { data: action.guidelineConfig, loading: false }
      })
    case CompanyActionType.LIST_REPORTAGE_CONFIG_SUCCESS:
      return produce(state, draft => {
        draft.reportageConfigs[action.companyId] = { data: action.reportageConfig, loading: false }
      })
    case CompanyActionType.LIST_STORY_CONFIG_SUCCESS:
      return produce(state, draft => {
        draft.storyConfigs[action.companyId] = { data: action.storyConfig, loading: false }
      })
    case CompanyActionType.LIST_RUNDOWN_CONFIG_SUCCESS:
      return produce(state, draft => {
        draft.rundownConfigs[action.companyId] = { data: action.rundownConfig, loading: false }
      })
    case CompanyActionType.LIST_REMOTE_SUCCESS:
      return produce(state, draft => {
        draft.remoteCompanies[action.companyId] = { data: action.remoteCompanies, loading: false }
      })
    case CompanyActionType.LIST_EDITORIALS_SUCCESS:
      return produce(state, draft => {
        draft.editorials[action.companyId] = { data: action.editorials, loading: false }
      })
    case CompanyActionType.LIST_TEAMS_SUCCESS:
      return produce(state, draft => {
        draft.teams[action.companyId] = { data: action.teams, loading: false }
      })
    case CompanyActionType.LIST_TYPES_SUCCESS:
      return produce(state, draft => {
        draft.types[action.companyId] = { data: action.types, loading: false }
      })

    // FAILURE CASES
    case CompanyActionType.LIST_PROGRAMS_FAILURE:
      return produce(state, draft => {
        draft.programs[action.companyId] = { data: [], loading: false }
      })
    case CompanyActionType.LIST_CLASSIFICATIONS_FAILURE:
      return produce(state, draft => {
        draft.classifications[action.companyId] = { data: [], loading: false }
      })
    case CompanyActionType.LIST_USERS_FAILURE:
      return produce(state, draft => {
        draft.users[action.companyId] = { data: [], loading: false }
      })
    case CompanyActionType.LIST_EDITORS_FAILURE:
      return produce(state, draft => {
        draft.editors[action.companyId] = { data: [], loading: false }
      })
    case CompanyActionType.LIST_PRODUCERS_FAILURE:
      return produce(state, draft => {
        draft.producers[action.companyId] = { data: [], loading: false }
      })
    case CompanyActionType.LIST_REPORTERS_FAILURE:
      return produce(state, draft => {
        draft.reporters[action.companyId] = { data: [], loading: false }
      })
    case CompanyActionType.LIST_VEHICLES_FAILURE:
      return produce(state, draft => {
        draft.vehicles[action.companyId] = { data: [], loading: false }
      })
    case CompanyActionType.LIST_DOCUMENT_CONFIG_FAILURE:
      return produce(state, draft => {
        draft.documentConfigs[action.companyId] = { data: undefined, loading: false }
      })
    case CompanyActionType.LIST_GUIDELINE_CONFIG_FAILURE:
      return produce(state, draft => {
        draft.guidelineConfigs[action.companyId] = { data: undefined, loading: false }
      })
    case CompanyActionType.LIST_REPORTAGE_CONFIG_FAILURE:
      return produce(state, draft => {
        draft.reportageConfigs[action.companyId] = { data: undefined, loading: false }
      })
    case CompanyActionType.LIST_STORY_CONFIG_FAILURE:
      return produce(state, draft => {
        draft.storyConfigs[action.companyId] = { data: undefined, loading: false }
      })
    case CompanyActionType.LIST_RUNDOWN_CONFIG_FAILURE:
      return produce(state, draft => {
        draft.rundownConfigs[action.companyId] = { data: undefined, loading: false }
      })
    case CompanyActionType.LIST_REMOTE_FAILURE:
      return produce(state, draft => {
        draft.remoteCompanies[action.companyId] = { data: [], loading: false }
      })
    case CompanyActionType.LIST_EDITORIALS_FAILURE:
      return produce(state, draft => {
        draft.editorials[action.companyId] = { data: [], loading: false }
      })
    case CompanyActionType.LIST_TEAMS_FAILURE:
      return produce(state, draft => {
        draft.teams[action.companyId] = { data: [], loading: false }
      })
    case CompanyActionType.LIST_TYPES_FAILURE:
      return produce(state, draft => {
        draft.types[action.companyId] = { data: [], loading: false }
      })

    // REQUEST CASES
    case CompanyActionType.LIST_PROGRAMS_REQUEST:
      return produce(state, draft => {
        draft.programs[action.companyId] = { data: [], loading: true }
      })
    case CompanyActionType.LIST_CLASSIFICATIONS_REQUEST:
      return produce(state, draft => {
        draft.classifications[action.companyId] = { data: [], loading: true }
      })
    case CompanyActionType.LIST_USERS_REQUEST:
      return produce(state, draft => {
        draft.users[action.companyId] = { data: [], loading: true }
      })
    case CompanyActionType.LIST_EDITORS_REQUEST:
      return produce(state, draft => {
        draft.editors[action.companyId] = { data: [], loading: true }
      })
    case CompanyActionType.LIST_PRODUCERS_REQUEST:
      return produce(state, draft => {
        draft.producers[action.companyId] = { data: [], loading: true }
      })
    case CompanyActionType.LIST_REPORTERS_REQUEST:
      return produce(state, draft => {
        draft.reporters[action.companyId] = { data: [], loading: true }
      })
    case CompanyActionType.LIST_VEHICLES_REQUEST:
      return produce(state, draft => {
        draft.vehicles[action.companyId] = { data: [], loading: true }
      })
    case CompanyActionType.LIST_DOCUMENT_CONFIG_REQUEST:
      return produce(state, draft => {
        draft.documentConfigs[action.companyId] = { data: undefined, loading: true }
      })
    case CompanyActionType.LIST_GUIDELINE_CONFIG_REQUEST:
      return produce(state, draft => {
        draft.guidelineConfigs[action.companyId] = { data: undefined, loading: true }
      })
    case CompanyActionType.LIST_REPORTAGE_CONFIG_REQUEST:
      return produce(state, draft => {
        draft.reportageConfigs[action.companyId] = { data: undefined, loading: true }
      })
    case CompanyActionType.LIST_STORY_CONFIG_REQUEST:
      return produce(state, draft => {
        draft.storyConfigs[action.companyId] = { data: undefined, loading: true }
      })
    case CompanyActionType.LIST_RUNDOWN_CONFIG_REQUEST:
      return produce(state, draft => {
        draft.rundownConfigs[action.companyId] = { data: undefined, loading: true }
      })
    case CompanyActionType.LIST_REMOTE_REQUEST:
      return produce(state, draft => {
        draft.remoteCompanies[action.companyId] = { data: [], loading: true }
      })
    case CompanyActionType.LIST_EDITORIALS_REQUEST:
      return produce(state, draft => {
        draft.editorials[action.companyId] = { data: [], loading: true }
      })
    case CompanyActionType.LIST_TEAMS_REQUEST:
      return produce(state, draft => {
        draft.teams[action.companyId] = { data: [], loading: true }
      })
    case CompanyActionType.LIST_TYPES_REQUEST:
      return produce(state, draft => {
        draft.types[action.companyId] = { data: [], loading: true }
      })

    default:
      return state
  }
}

export default companiesReducer
