import * as Sentry from '@sentry/react'
import { CaptureConsole, Dedupe, ExtraErrorData } from '@sentry/integrations'

if (process.env.NODE_ENV === 'production') {
  try {
    Sentry.init({
      dsn: 'https://38f567a1745e4b15b7ca63e078a865a0@o968108.ingest.sentry.io/5919520',
      autoSessionTracking: true,
      integrations: [
        // new BrowserTracing(),
        new CaptureConsole({ levels: ['error'] }),
        new Dedupe(),
        new ExtraErrorData({ depth: 3 }),
      ],
      release: process.env.REACT_APP_VERSION,
      // tracesSampleRate: 0.5,
    })
  } catch (error) {
    console.error('Failed to initialize Sentry', error)
  }
}

export function setTag(key: string, value: string) {
  try {
    Sentry.setTag(key, value)
  } catch (error) {
    console.error('Failed to set Sentry tag', error)
  }
}
