import { Contact, AgendaContact, Page } from '@anews/types'

import { PAGE_SIZE } from '../../consts/pagination'

export enum ActionType {
  NEW = '@contact/NEW',
  UNLOAD = '@contact/UNLOAD',
  FILTER_REQUEST = '@contact/FILTER_REQUEST',
  FILTER_SUCCESS = '@contact/FILTER_SUCCESS',
  FILTER_FAILURE = '@contact/FILTER_FAILURE',
  LOAD_REQUEST = '@contact/LOAD_REQUEST',
  LOAD_SUCCESS = '@contact/LOAD_SUCCESS',
  LOAD_FAILURE = '@contact/LOAD_FAILURE',
  CREATE_REQUEST = '@contact/CREATE_REQUEST',
  CREATE_SUCCESS = '@contact/CREATE_SUCCESS',
  CREATE_FAILURE = '@contact/CREATE_FAILURE',
  UPDATE_REQUEST = '@contact/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@contact/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@contact/UPDATE_FAILURE',
  REMOVE_REQUEST = '@contact/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@contact/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@contact/REMOVE_FAILURE',
}

export interface NewAction {
  type: ActionType.NEW
  contact?: Contact
}

export interface FilterContactsAction {
  type: ActionType.FILTER_REQUEST
  filter?: string
  page: number
  size: number
}

export interface LoadContactAction {
  type: ActionType.LOAD_REQUEST
  id: number
  edit: boolean
}

export interface CreateContactAction {
  type: ActionType.CREATE_REQUEST
  contact: Contact
  hidden: boolean
}

export interface UpdateContactAction {
  type: ActionType.UPDATE_REQUEST
  contact: Contact
  hidden: boolean
}

export interface RemoveContactAction {
  type: ActionType.REMOVE_REQUEST
  ids: number[]
}

export type ContactAction =
  /* newContact() */
  | NewAction
  /* unloadContact() */
  | { type: ActionType.UNLOAD }
  /* filterContacts() */
  | FilterContactsAction
  | { type: ActionType.FILTER_SUCCESS; result: Page<AgendaContact> }
  | { type: ActionType.FILTER_FAILURE; error: Error }
  /* loadContact() */
  | LoadContactAction
  | { type: ActionType.LOAD_SUCCESS; contact: Contact | AgendaContact; edit: boolean }
  | { type: ActionType.LOAD_FAILURE; error: Error }
  /* createContact() */
  | CreateContactAction
  | { type: ActionType.CREATE_SUCCESS; contact: Contact; hidden: boolean }
  | { type: ActionType.CREATE_FAILURE; error: Error }
  /* updateContact() */
  | UpdateContactAction
  | { type: ActionType.UPDATE_SUCCESS; contact: Contact; hidden: boolean }
  | { type: ActionType.UPDATE_FAILURE; error: Error }
  /* removeContact() */
  | RemoveContactAction
  | { type: ActionType.REMOVE_SUCCESS; ids: number[] }
  | { type: ActionType.REMOVE_FAILURE; error: Error }

//
//	newContact()
//

export const newContact = (contact?: Contact): ContactAction => ({ type: ActionType.NEW, contact })

//
//	unloadContact()
//

export const unloadContact = (): ContactAction => ({ type: ActionType.UNLOAD })

//
//	filterContacts()
//

export const filterContacts = (
  filter?: string,
  page: number = 0,
  size: number = PAGE_SIZE,
): ContactAction => ({
  type: ActionType.FILTER_REQUEST,
  filter,
  page,
  size,
})

export const filterContactsSuccess = (result: Page<AgendaContact>): ContactAction => ({
  type: ActionType.FILTER_SUCCESS,
  result,
})

export const filterContactsFailure = (error: Error): ContactAction => ({
  type: ActionType.FILTER_FAILURE,
  error,
})

//
//	loadContact()
//

export const loadContact = (id: number, edit: boolean): ContactAction => ({
  type: ActionType.LOAD_REQUEST,
  id,
  edit,
})

export const loadContactSuccess = (contact: Contact, edit: boolean): ContactAction => ({
  type: ActionType.LOAD_SUCCESS,
  contact,
  edit,
})

export const loadContactFailure = (error: Error): ContactAction => ({
  type: ActionType.LOAD_FAILURE,
  error,
})

//
//	createContact()
//

export const createContact = (contact: Contact, hidden: boolean): ContactAction => ({
  type: ActionType.CREATE_REQUEST,
  contact,
  hidden,
})

export const createContactSuccess = (contact: Contact, hidden: boolean): ContactAction => ({
  type: ActionType.CREATE_SUCCESS,
  contact,
  hidden,
})

export const createContactFailure = (error: Error): ContactAction => ({
  type: ActionType.CREATE_FAILURE,
  error,
})

//
//	updateContact()
//

export const updateContact = (contact: Contact, hidden: boolean): ContactAction => ({
  type: ActionType.UPDATE_REQUEST,
  contact,
  hidden,
})

export const updateContactSuccess = (contact: Contact, hidden: boolean): ContactAction => ({
  type: ActionType.UPDATE_SUCCESS,
  contact,
  hidden,
})

export const updateContactFailure = (error: Error): ContactAction => ({
  type: ActionType.UPDATE_FAILURE,
  error,
})

//
//	removeContact()
//

export const removeContact = (ids: number[]): ContactAction => ({
  type: ActionType.REMOVE_REQUEST,
  ids,
})

export const removeContactSuccess = (ids: number[]): ContactAction => ({
  type: ActionType.REMOVE_SUCCESS,
  ids,
})

export const removeContactFailure = (error: Error): ContactAction => ({
  type: ActionType.REMOVE_FAILURE,
  error,
})
