import { call, put, takeLatest } from 'redux-saga/effects'

import { documentApi as api } from '@anews/api'
import { DocumentConfig } from '@anews/types'

import {
  DocumentActions,
  DocumentActionMap as ActionMap,
  DocumentActionType as ActionType,
  NotificationActions,
} from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const {
  loadConfigSuccess,
  loadConfigFailure,
  updateConfigSuccess,
  updateConfigFailure,
  createConfigSuccess,
  createConfigFailure,
} = DocumentActions

const { notifyError } = NotificationActions

/* Watchers */

function* loadConfigSaga(): Generator {
  try {
    const config = yield call(api.loadConfig)
    yield put(loadConfigSuccess(config as DocumentConfig))
  } catch (error) {
    yield put(loadConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createConfigSaga(action: ActionMap<ActionType.CREATE_CONFIG_REQUEST>): Generator {
  try {
    const config = yield call(api.createConfig, action.config)
    yield put(createConfigSuccess(config as DocumentConfig))
  } catch (error) {
    yield put(createConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateConfigSaga(action: ActionMap<ActionType.UPDATE_CONFIG_REQUEST>): Generator {
  try {
    const config = yield call(api.updateConfig, action.config)
    yield put(updateConfigSuccess(config as DocumentConfig))
  } catch (error) {
    yield put(updateConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LOAD_CONFIG_REQUEST, loadConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_CONFIG_REQUEST, createConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_CONFIG_REQUEST, updateConfigSaga)
  },
])
