import { Editor, Transforms, Text } from 'slate'

import { RichTextBlock } from '@anews/types'
import { isBlank } from '@anews/utils'

import { richTextPalette } from './RichTextEditor'

export function toggleRichTextComment(editor: Editor) {
  const { selection } = editor
  if (selection) {
    const fragment: RichTextBlock[] = JSON.parse(JSON.stringify(Editor.fragment(editor, selection)))

    // processa paragrafos individualmente
    fragment.forEach(paragraph => {
      const { children } = paragraph
      if (Array.isArray(children)) {
        if (children.length === 1) {
          if (isBlank(children[0].text)) return
          const isCommented = children[0].text.startsWith('[') && children[0].text.endsWith(']')
          children[0].text = isCommented ? children[0].text.slice(1, -1) : `[${children[0].text}]`
        } else {
          const firstNode = children[0]
          const lastNode = children[children.length - 1]
          if (!firstNode || !lastNode || isBlank(firstNode.text) || isBlank(lastNode.text)) return
          const isCommented = firstNode.text.startsWith('[') && lastNode.text.endsWith(']')
          firstNode.text = isCommented ? firstNode.text.substring(1) : `[${children[0].text}`
          lastNode.text = isCommented ? lastNode.text.slice(0, -1) : `${lastNode.text}]`
        }
      }
    })
    Transforms.insertFragment(editor, fragment, { hanging: true })
  }
}

/**
 * Este hook deve ser utilizado na criação do editor, juntamente com withReact e/ou withHistory
 * */
export const withComment = (editor: Editor) => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (Text.isText(node)) {
      const commentMatch = node.text.match(/(\[)(.+?)(\])/)

      const isComment = node.color === richTextPalette.comment

      if (commentMatch) {
        const [searchMatch] = commentMatch
        const { index: startIndex, input } = commentMatch
        const endIndex = (startIndex ?? 0) + searchMatch.length
        const excedent = (input?.length ?? 0) - searchMatch.length
        if (!isComment) {
          Transforms.setNodes(
            editor,
            { color: richTextPalette.comment },
            {
              at: {
                anchor: { path, offset: startIndex || 0 },
                focus: { path, offset: endIndex },
              },
              match: n => Text.isText(n),
              split: true,
            },
          )
          return
        }
        if (isComment && excedent > 0) {
          Transforms.setNodes(
            editor,
            { color: 'inherit' },
            {
              at: {
                anchor: { path, offset: endIndex },
                focus: { path, offset: endIndex + excedent },
              },
              match: n => Text.isText(n),
              split: true,
            },
          )
          return
        }
      }
      if (!commentMatch && isComment) {
        Transforms.setNodes(
          editor,
          { color: 'inherit' },
          {
            match: n => Text.isText(n),
          },
        )
        return
      }
    }
    return normalizeNode([node, path])
  }

  return editor
}
