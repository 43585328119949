import { MosItemData } from '@anews/types'

import { objToXml } from '../utils/xml-utils'

import { Item } from './types'

export function mosDataToItem(data: MosItemData, itemID: string): Item {
  return {
    itemID,
    itemSlug: data.slug,
    objSlug: data.slug,
    objType: data.type,
    objID: data.objId,
    mosID: data.mosId,
    mosPlugInID: data.mosPluginId,
    mosAbstract: data.mosAbstract,
    itemChannel: data.channel,
    itemEdStart: data.edStart,
    itemEdDur: data.edDur,
    itemUserTimingDur: data.userTimingDur,
    macroIn: data.macroIn,
    macroOut: data.macroOut,
    mosExternalMetadata: data.mosExternalMetadata,
    objPaths: {
      objPaths:
        data.paths?.map(p => ({ techDescription: p.techDescription, value: p.path || '' })) || [],
      objProxyPaths:
        data.proxyPaths?.map(p => ({ techDescription: p.techDescription, value: p.path || '' })) ||
        [],
      objMetadataPaths:
        data.metadataPaths?.map(p => ({
          techDescription: p.techDescription,
          value: p.path || '',
        })) || [],
    },
  }
}

export function itemToMosData(item: Item): MosItemData {
  const { mosAbstract } = item

  const mosAbstractStr = typeof mosAbstract === 'object' ? objToXml(mosAbstract) : mosAbstract || ''

  return {
    slug: item.itemSlug || item.objSlug,
    objId: item.objID,
    mosId: item.mosID,
    mosPluginId: item.mosPlugInID,
    mosAbstract: mosAbstractStr,
    type: item.objType,
    channel: item.itemChannel,
    edStart: item.itemEdStart,
    edDur: item.itemEdDur,
    userTimingDur: item.itemUserTimingDur,
    trigger: item.itemTrigger,
    macroIn: item.macroIn,
    macroOut: item.macroOut,
    paths: (item.objPaths?.objPaths || []).map(path => ({
      techDescription: path.techDescription,
      path: path.value,
    })),
    proxyPaths: (item.objPaths?.objProxyPaths || []).map(path => ({
      techDescription: path.techDescription,
      path: path.value,
    })),
    metadataPaths: (item.objPaths?.objMetadataPaths || []).map(path => ({
      techDescription: path.techDescription,
      path: path.value,
    })),
    mosExternalMetadata: item.mosExternalMetadata
      ? item.mosExternalMetadata.map(mem => (typeof mem === 'string' ? mem : objToXml(mem)))
      : [],
  }
}
