import { toggleCase } from './string-utils';
export function richToPlain(content, stripComments) {
    if (stripComments === void 0) { stripComments = false; }
    var result = '';
    if (!content) {
        return result;
    }
    content.forEach(function (p) {
        if (Array.isArray(p.children)) {
            p.children.forEach(function (leaf) {
                if (!(stripComments && leaf.comment))
                    result += leaf.text;
            });
        }
        result += '\n';
    });
    return result;
}
export function toggleRichFormat(text, format) {
    if (!text) {
        return undefined;
    }
    var clonedText = JSON.parse(JSON.stringify(text));
    clonedText.forEach(function (p) {
        if (Array.isArray(p.children)) {
            p.children.forEach(function (leaf) {
                leaf[format] = !leaf[format];
            });
        }
    });
    return clonedText;
}
export function richHasContent(text) {
    return !!(Array.isArray(text) &&
        text.find(function (t) {
            return Array.isArray(t === null || t === void 0 ? void 0 : t.children) &&
                t.children.find(function (c) { return typeof (c === null || c === void 0 ? void 0 : c.text) === 'string' && c.text.trim().length > 0; });
        }));
}
export function toggleRichChildrenCase(children) {
    var mappings = [];
    var fullText = '';
    children.forEach(function (child, block) {
        if (child.type === 'paragraph' && Array.isArray(child.children)) {
            child.children.forEach(function (leaf, index) {
                var prev = fullText.length;
                fullText += leaf.text;
                mappings.push({ at: [block, index], global: [prev, fullText.length] });
            });
        }
    });
    fullText = toggleCase(fullText);
    return mappings.map(function (m) { return ({ at: m.at, text: fullText.substring(m.global[0], m.global[1]) }); });
}
