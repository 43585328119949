import { del, get, post, put, query } from './client';
var endpoint = '/api/contacts';
var contactApi = {
    pageFilter: function (filter, page, size) {
        if (filter === void 0) { filter = ''; }
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 25; }
        return get(query(endpoint + "/filter", { filter: filter, page: page, size: size }));
    },
    load: function (id) {
        return get(endpoint + "/" + id);
    },
    usageCount: function (id) {
        return get(endpoint + "/" + id + "/usage_count");
    },
    create: function (contact, hidden) {
        if (hidden) {
            return post(endpoint + "/hidden", contact);
        }
        return post(endpoint, contact);
    },
    update: function (contact, hidden) {
        if (hidden) {
            return put(endpoint + "/hidden", contact);
        }
        return put(endpoint, contact);
    },
    remove: function (ids) {
        return del(query(endpoint, { ids: ids }));
    },
    countries: function () {
        return get(endpoint + "/numbers/countries");
    },
    labels: function () {
        return get(endpoint + "/numbers/labels");
    },
};
export default contactApi;
