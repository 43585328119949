import React, { CSSProperties, memo, useCallback, useMemo, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import styled from '@emotion/styled'

import { BlockOutlined, CopyOutlined, DiffOutlined } from '@ant-design/icons'

import Alert from 'antd/es/alert'
import Button from 'antd/es/button'
import Collapse from 'antd/es/collapse'
import Divider from 'antd/es/divider'
import AntPageHeader from 'antd/es/page-header'

import Tooltip from 'antd/es/tooltip'
import message from 'antd/es/message'

import {
  DocumentType,
  Story,
  StoryConfig,
  RundownConfig,
  StorySubSection,
  StorySubSectionType,
  StoryRevision,
} from '@anews/types'

import { highlight, isBlank, richHasContent } from '@anews/utils'
import { storyApi } from '@anews/api'

import { useTranslation } from '../../../../i18n'
import { SelectorMap } from '../../../../redux/selectors/helpers'
import { isElectron } from '../../../../utils/electron-utils'

import { CarouselModal, ViewerItem } from '../../../global'

import RevisionCard from '../../revision/RevisionCard'

import RichTextViewer from '../../../global/RichText/RichTextViewer'

import StorySectionView from './StorySectionView'
import FullHeader from './FullHeader'
import PreviewHeader from './PreviewHeader'
import storyToClipboard from './storyToClipboard'
import CopyableCGValue from './CopyableCGValue'

const { Panel } = Collapse

const PageHeader = styled(AntPageHeader)`
  &.ant-page-header {
    padding: 4px 8px;
  }
  .ant-page-header-heading-title {
    line-height: unset;
  }
  .ant-page-header-content {
    padding-top: 4px;
  }
`

function extractCGs(story: Story): StorySubSection[] {
  const { headSection, linkSection, liveVOSection, vtSection, footerSection } = story

  return [headSection, linkSection, liveVOSection, vtSection, footerSection]
    .flatMap(section => section?.subSections || [])
    .flatMap(sub => [sub, ...(sub.subSections || [])])
    .filter(sub => sub.type === StorySubSectionType.CG || sub.type === StorySubSectionType.MOS_CG)
}

const runningOnClient = isElectron()

interface Props {
  story?: Story
  editable?: boolean
  preview?: boolean
  detached?: boolean
  hideCgs?: boolean
  companies: SelectorMap<string>
  programs: SelectorMap<string>
  types: SelectorMap<Partial<DocumentType>>
  users: SelectorMap<string>
  rundownConfig?: RundownConfig
  storyConfig?: StoryConfig
  headerStyle?: CSSProperties
  onEdit?: (id: number) => void
  onDetach?: () => void
  onClose?: () => void
}

function StoryViewer({
  story,
  detached,
  editable,
  preview,
  companies,
  programs,
  types,
  users,
  hideCgs,
  rundownConfig,
  storyConfig,
  headerStyle,
  onClose,
  onDetach,
  onEdit,
}: Props) {
  const { t } = useTranslation()

  const cgs = useMemo(() => (story ? extractCGs(story) : []), [story])

  const getText = useCallback(
    e => {
      if (e.detail === 1 && story) {
        return storyToClipboard(story, { programs, types, users })
      }
    },
    [programs, story, types, users],
  )

  //
  // Versões
  //

  const [versionsVisible, setVersionsVisible] = useState(false)
  const [storyVersions, setStoryVersions] = useState<StoryRevision[]>([])

  const versionsCards = useMemo(
    () =>
      storyVersions.map(version => (
        <RevisionCard
          date={version.revisionDate}
          ip={version.revisorIP}
          nickname={version.revisorNickname}
          slug={version.slug}
          content={version}
          isRadio={story?.radio}
        />
      )),
    [story, storyVersions],
  )

  const versionsHandler = useCallback(async () => {
    if (story) {
      try {
        const result = await storyApi.loadChangesHistory(story?.id)
        setStoryVersions(result)
        setVersionsVisible(true)
      } catch (error) {
        console.error(error)
        message.error(t('error:loadFailed'))
      }
    }
  }, [t, story])

  if (!story) {
    if (detached) {
      return <Alert message={t('story:viewerEmptyAndDetached')} type="warning" />
    }
    return null
  }

  const actions: JSX.Element[] = []
  const detachable = !!onDetach

  if (!preview) {
    if (detachable && !detached && !runningOnClient) {
      actions.push(
        <Tooltip key="detach" placement="bottom" title={t('phrases:viewInWindow')}>
          <Button onClick={onDetach} icon={<BlockOutlined />} />
        </Tooltip>,
      )
    }

    // A ação de copiar é executada na janela pai, e tem uma regra de segurança do navegador que bloqueia copiar texto em uma janela sem foco
    // Por isso não oferece essa opção na janela separada
    if (!detached) {
      actions.push(
        <Tooltip key="copy" placement="bottom" title={t('phrases:copyToClipboard')}>
          <Button onClick={getText} icon={<CopyOutlined />} />
        </Tooltip>,
      )
    }

    actions.push(
      <Tooltip key="version" placement="bottom" title={t('words:versions')}>
        <Button onClick={versionsHandler} icon={<DiffOutlined />} />
      </Tooltip>,
    )

    if (editable) {
      actions.push(
        <Button key="edit" onClick={() => onEdit && onEdit(story.id)}>
          {t('words:edit')}
        </Button>,
      )
    }

    if (onClose) {
      actions.push(
        <Button key="close" onClick={onClose} type="primary">
          {t('words:close')}
        </Button>,
      )
    }
  }

  const {
    headSection,
    linkSection,
    liveVOSection,
    recordedVOSection,
    vtSection,
    footerSection,
    information,
  } = story

  const type = types[story.typeId!] ? types[story.typeId!].abbreviation : undefined

  //
  //  Conteúdo
  //
  const contentChildren = []

  /* Informações */
  if (richHasContent(story.information)) {
    contentChildren.push(
      <ViewerItem key="info" label={t('words:info')}>
        <RichTextViewer content={information} />
      </ViewerItem>,
    )
  }

  let vtSectionView = vtSection
  let liveVOSectionView = liveVOSection

  if (story.manualVt) {
    vtSectionView = vtSection ? { ...vtSection, duration: story.vt } : undefined
    liveVOSectionView = liveVOSection ? { ...liveVOSection, duration: story.vt } : undefined
  }

  //
  //  Render
  //
  return (
    <PageHeader
      title={
        <Tooltip
          title={highlight(
            story.visible
              ? isBlank(type)
                ? `${story.page}. ${story.slug}`
                : `${story.page}. ${type} ${story.slug}`
              : `${story.page}. ${t('story:private')}`,
          )}
          placement="left"
        >
          {highlight(
            story.visible
              ? isBlank(type)
                ? `${story.page}. ${story.slug}`
                : `${story.page}. ${type} ${story.slug}`
              : `${story.page}. ${t('story:private')}`,
          )}
        </Tooltip>
      }
      extra={actions}
      style={headerStyle}
    >
      {preview ? (
        <PreviewHeader story={story} companies={companies} users={users} />
      ) : (
        <FullHeader
          story={story}
          companies={companies}
          programs={programs}
          users={users}
          rundownConfig={rundownConfig}
          storyConfig={storyConfig}
        />
      )}

      <StorySectionView
        section={story.visible ? headSection : undefined}
        users={users}
        isRadio={story?.radio || false}
        preview={preview}
        hideCgs={hideCgs}
      />
      <StorySectionView
        section={story.visible ? linkSection : undefined}
        users={users}
        duration={story.link}
        isRadio={story?.radio || false}
        preview={preview}
        hideCgs={hideCgs}
      />
      <StorySectionView
        section={story.visible ? vtSectionView : undefined}
        users={users}
        duration={story.vt}
        isRadio={story?.radio || false}
        preview={preview}
        hideCgs={hideCgs}
      />
      <StorySectionView
        section={story.visible ? liveVOSectionView : undefined}
        users={users}
        isRadio={story?.radio || false}
        preview={preview}
        hideCgs={hideCgs}
      />
      <StorySectionView
        section={story.visible ? footerSection : undefined}
        users={users}
        isRadio={story?.radio || false}
        preview={preview}
        hideCgs={hideCgs}
      />
      <StorySectionView
        section={story.visible ? recordedVOSection : undefined}
        users={users}
        isRadio={story?.radio || false}
        preview={preview}
        hideCgs={hideCgs}
      />

      {richHasContent(information) && story.visible && (
        <div>
          <Divider />
          <ViewerItem.Header className="viewer-header">{t('words:info')}</ViewerItem.Header>
          <ViewerItem>
            <RichTextViewer content={information} />
          </ViewerItem>
        </div>
      )}

      {/* CGs */}

      {cgs && cgs.length > 0 && story.visible && (
        <>
          <Divider style={{ margin: '8px 0' }} />
          <Collapse>
            <Panel key="1" header={t('story:allCgs')}>
              {cgs.map(cg => (
                <div style={{ marginBottom: '20px' }} key={cg.id}>
                  {cg.type === StorySubSectionType.MOS_CG ? (
                    <div key={cg.id}>
                      <span>{cg.slug}</span>
                      {cg.media?.abstractText && (
                        <div>{ReactHtmlParser(cg.media.abstractText)}</div>
                      )}
                    </div>
                  ) : (
                    <div key={cg.id}>
                      <b>{`${cg.templateName}${
                        cg.code && cg.code > 0 ? ` ${t('words:codeAbbr')}: ${cg.code}` : ''
                      }`}</b>
                      {cg.cgFields?.map(field => (
                        <div key={field.id}>
                          <b>{field.name}: </b>
                          {preview ? <CopyableCGValue value={field.value} /> : field.value}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </Panel>
          </Collapse>
        </>
      )}

      {/* Versões */}
      <CarouselModal
        content={versionsCards}
        visible={versionsVisible}
        title={t('words:versions')}
        onClose={() => setVersionsVisible(false)}
      />
    </PageHeader>
  )
}

export default memo(StoryViewer)
