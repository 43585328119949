import { Guideline, GuidelineConfig } from '@anews/types'

export enum ActionType {
  NEW = '@guideline/NEW',
  CLEAR = '@guideline/CLEAR',
  UNLOAD = '@guideline/UNLOAD',
  NEW_TAB = '@guideline/NEW_TAB',
  CLOSE_TAB = '@guideline/CLOSE_TAB',
  CHANGE_TAB = '@guideline/CHANGE_TAB',
  UPDATE_FORM = '@guideline/UPDATE_FORM',
  // listDateGuidelines()
  DATE_LIST_REQUEST = '@guideline/DATE_LIST_REQUEST',
  DATE_LIST_SUCCESS = '@guideline/DATE_LIST_SUCCESS',
  DATE_LIST_FAILURE = '@guideline/DATE_LIST_FAILURE',
  // loadGuideline()
  LOAD_REQUEST = '@guideline/LOAD_REQUEST',
  LOAD_SUCCESS = '@guideline/LOAD_SUCCESS',
  LOAD_FAILURE = '@guideline/LOAD_FAILURE',
  // createGuideline()
  CREATE_REQUEST = '@guideline/CREATE_REQUEST',
  CREATE_SUCCESS = '@guideline/CREATE_SUCCESS',
  CREATE_FAILURE = '@guideline/CREATE_FAILURE',
  // updateGuideline()
  UPDATE_REQUEST = '@guideline/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@guideline/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@guideline/UPDATE_FAILURE',
  // patchGuideline()
  PATCH_REQUEST = '@guideline/PATCH_REQUEST',
  PATCH_SUCCESS = '@guideline/PATCH_SUCCESS',
  PATCH_FAILURE = '@guideline/PATCH_FAILURE',
  // changeStateGuideLine()
  CHANGE_STATE_REQUEST = '@guideline/CHANGE_STATE_REQUEST',
  CHANGE_STATE_SUCCESS = '@guideline/CHANGE_STATE_SUCCESS',
  CHANGE_STATE_FAILURE = '@guideline/CHANGE_STATE_FAILURE',
  // removeGuideline()
  REMOVE_REQUEST = '@guideline/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@guideline/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@guideline/REMOVE_FAILURE',
  // loadConfig()
  LOAD_CONFIG_REQUEST = '@guideline/LOAD_CONFIG_REQUEST',
  LOAD_CONFIG_SUCCESS = '@guideline/LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_FAILURE = '@guideline/LOAD_CONFIG_FAILURE',
  // createConfig()
  CREATE_CONFIG_REQUEST = '@guideline/CREATE_CONFIG_REQUEST',
  CREATE_CONFIG_SUCCESS = '@guideline/CREATE_CONFIG_SUCCESS',
  CREATE_CONFIG_FAILURE = '@guideline/CREATE_CONFIG_FAILURE',
  // updateConfig()
  UPDATE_CONFIG_REQUEST = '@guideline/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@guideline/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@guideline/UPDATE_CONFIG_FAILURE',
  // websocket
  WS_CREATE = '@guideline/WS_CREATE',
  WS_DELETE = '@guideline/WS_DELETE',
  WS_UPDATE = '@guideline/WS_UPDATE',
  WS_PATCH = '@guideline/WS_PATCH',
}

export interface DateListGuidelineAction {
  type: ActionType.DATE_LIST_REQUEST
  uuid: string
  date: string
  programId?: number
  allPrograms: boolean
}

export interface LoadGuidelineAction {
  type: ActionType.LOAD_REQUEST
  target: number | Guideline
  edit: boolean
}

export interface CreateGuidelineAction {
  type: ActionType.CREATE_REQUEST
  guideline: Guideline
  lock: boolean
}

export interface UpdateGuidelineAction {
  type: ActionType.UPDATE_REQUEST
  guideline: Guideline
}

export interface RemoveGuidelinesAction {
  type: ActionType.REMOVE_REQUEST
  ids: number[]
}

export interface PatchGuidelineAction {
  type: ActionType.PATCH_REQUEST
  guidelineId: number
  field: keyof Guideline
  newValue: any
  oldValue: any
}

export interface ChangeStateGuidelineAction {
  type: ActionType.CHANGE_STATE_REQUEST
  guidelinesIds: number[] | number
  state: string
}

export interface CreateGuidelineConfigAction {
  type: ActionType.CREATE_CONFIG_REQUEST
  config: GuidelineConfig
}

export interface UpdateGuidelineConfigAction {
  type: ActionType.UPDATE_CONFIG_REQUEST
  config: GuidelineConfig
}

export type GuidelineAction =
  /* dateListGuidelines() */
  | DateListGuidelineAction
  | {
      type: ActionType.DATE_LIST_SUCCESS
      guidelines: Guideline[]
      uuid: string
      date: string
      programId?: number
      allPrograms: boolean
    }
  | { type: ActionType.DATE_LIST_FAILURE; error: Error }
  /* loadGuideline() */
  | LoadGuidelineAction
  | { type: ActionType.LOAD_SUCCESS; guideline: Guideline; edit: boolean }
  | { type: ActionType.LOAD_FAILURE; error: Error }
  /* createGuideline() */
  | CreateGuidelineAction
  | { type: ActionType.CREATE_SUCCESS; guideline: Guideline }
  | { type: ActionType.CREATE_FAILURE; error: Error }
  /* updateGuideline() */
  | UpdateGuidelineAction
  | { type: ActionType.UPDATE_SUCCESS; guideline: Guideline }
  | { type: ActionType.UPDATE_FAILURE; error: Error }
  /* removeGuidelines() */
  | RemoveGuidelinesAction
  | { type: ActionType.REMOVE_SUCCESS; ids: number[] }
  | { type: ActionType.REMOVE_FAILURE; error: Error }
  /* patchGuideline() */
  | PatchGuidelineAction
  | { type: ActionType.PATCH_SUCCESS }
  | {
      type: ActionType.PATCH_FAILURE
      error: Error
      guidelineId: number
      field: keyof Guideline
      newValue: any
      oldValue: any
    }
  /* changeStateGuideline() */
  | ChangeStateGuidelineAction
  | { type: ActionType.CHANGE_STATE_SUCCESS }
  | {
      type: ActionType.CHANGE_STATE_FAILURE
      error: Error
      guidelinesIds: number[] | number
      state: string
    }
  /* newGuideline() */
  | { type: ActionType.NEW; guideline: Guideline }
  /* unloadGuideline() */
  | { type: ActionType.UNLOAD }
  /* clearGuidelines() */
  | { type: ActionType.CLEAR }
  /* newGuidelinesTab() */
  | { type: ActionType.NEW_TAB }
  /* changeGuidelineTab() */
  | { type: ActionType.CHANGE_TAB; uuid: string }
  /* closeGuidelineTab() */
  | { type: ActionType.CLOSE_TAB; uuid: string }
  /* updateForm() */
  | { type: ActionType.UPDATE_FORM; uuid: string; changes: Partial<Guideline> }
  /* loadConfig() */
  | { type: ActionType.LOAD_CONFIG_REQUEST }
  | { type: ActionType.LOAD_CONFIG_SUCCESS; config: GuidelineConfig }
  | { type: ActionType.LOAD_CONFIG_FAILURE; error: Error }
  /* createConfig() */
  | CreateGuidelineConfigAction
  | { type: ActionType.CREATE_CONFIG_SUCCESS; config: GuidelineConfig }
  | { type: ActionType.CREATE_CONFIG_FAILURE; error: Error }
  /* updateConfig() */
  | UpdateGuidelineConfigAction
  | { type: ActionType.UPDATE_CONFIG_SUCCESS; config: GuidelineConfig }
  | { type: ActionType.UPDATE_CONFIG_FAILURE; error: Error }
  /* wsCreateGuideline() */
  | { type: ActionType.WS_CREATE; guideline: Guideline }
  /* wsUpdateGuideline() */
  | { type: ActionType.WS_UPDATE; guideline: Guideline; onlyUpdateProduction?: boolean }
  /* wsDeleteGuideline() */
  | { type: ActionType.WS_DELETE; ids: number[] }
  /* wsPatchGuideline() */
  | { type: ActionType.WS_PATCH; guidelineId: number; changes: Partial<Guideline> }

//
//	dateListGuidelines()
//

export const dateListGuidelines = (
  uuid: string,
  date: string,
  programId: number | undefined,
  allPrograms: boolean,
): GuidelineAction => ({ type: ActionType.DATE_LIST_REQUEST, uuid, date, programId, allPrograms })

export const dateListGuidelinesSuccess = (
  guidelines: Guideline[],
  uuid: string,
  date: string,
  programId: number | undefined,
  allPrograms: boolean,
): GuidelineAction => ({
  type: ActionType.DATE_LIST_SUCCESS,
  uuid,
  date,
  programId,
  allPrograms,
  guidelines,
})

export const dateListGuidelinesFailure = (error: Error): GuidelineAction => ({
  type: ActionType.DATE_LIST_FAILURE,
  error,
})

//
//	loadGuideline()
//

export const loadGuideline = (target: number | Guideline, edit: boolean): GuidelineAction => ({
  type: ActionType.LOAD_REQUEST,
  target,
  edit,
})

export const loadGuidelineSuccess = (guideline: Guideline, edit: boolean): GuidelineAction => ({
  type: ActionType.LOAD_SUCCESS,
  guideline,
  edit,
})

export const loadGuidelineFailure = (error: Error): GuidelineAction => ({
  type: ActionType.LOAD_FAILURE,
  error,
})

//
//	createGuideline()
//

export const createGuideline = (guideline: Guideline, lock: boolean): GuidelineAction => ({
  type: ActionType.CREATE_REQUEST,
  guideline,
  lock,
})

export const createGuidelineSuccess = (guideline: Guideline): GuidelineAction => ({
  type: ActionType.CREATE_SUCCESS,
  guideline,
})

export const createGuidelineFailure = (error: Error): GuidelineAction => ({
  type: ActionType.CREATE_FAILURE,
  error,
})

//
//	updateGuideline()
//

export const updateGuideline = (guideline: Guideline): GuidelineAction => ({
  type: ActionType.UPDATE_REQUEST,
  guideline,
})

export const updateGuidelineSuccess = (guideline: Guideline): GuidelineAction => ({
  type: ActionType.UPDATE_SUCCESS,
  guideline,
})

export const updateGuidelineFailure = (error: Error): GuidelineAction => ({
  type: ActionType.UPDATE_FAILURE,
  error,
})

//
//	removeGuidelines()
//

export const removeGuidelines = (ids: number[]): GuidelineAction => ({
  type: ActionType.REMOVE_REQUEST,
  ids,
})

export const removeGuidelinesSuccess = (ids: number[]): GuidelineAction => ({
  type: ActionType.REMOVE_SUCCESS,
  ids,
})

export const removeGuidelinesFailure = (error: Error): GuidelineAction => ({
  type: ActionType.REMOVE_FAILURE,
  error,
})

//
//  patchGuideline()
//

export const patchGuideline = (
  guidelineId: number,
  field: keyof Guideline,
  newValue: any,
  oldValue: any,
): GuidelineAction => ({ type: ActionType.PATCH_REQUEST, guidelineId, field, newValue, oldValue })

export const patchGuidelineSuccess = (): GuidelineAction => ({ type: ActionType.PATCH_SUCCESS })

export const patchGuidelineFailure = (
  error: Error,
  guidelineId: number,
  field: keyof Guideline,
  newValue: any,
  oldValue: any,
): GuidelineAction => ({
  type: ActionType.PATCH_FAILURE,
  error,
  guidelineId,
  field,
  newValue,
  oldValue,
})

//
//  changeStateGuideline()
//

export const changeStateGuideline = (
  guidelinesIds: number[] | number,
  state: string,
): GuidelineAction => ({
  type: ActionType.CHANGE_STATE_REQUEST,
  guidelinesIds,
  state,
})

export const changeStateGuidelineSuccess = (): GuidelineAction => ({
  type: ActionType.CHANGE_STATE_SUCCESS,
})

export const changeStateGuidelineFailure = (
  error: Error,
  guidelinesIds: number[] | number,
  state: string,
): GuidelineAction => ({
  type: ActionType.CHANGE_STATE_FAILURE,
  error,
  guidelinesIds,
  state,
})

//
//	newGuideline()
//

export const newGuideline = (guideline: Guideline): GuidelineAction => ({
  type: ActionType.NEW,
  guideline,
})

//
//	unloadGuideline()
//

export const unloadGuideline = (): GuidelineAction => ({ type: ActionType.UNLOAD })

//
//	clearGuidelines()
//

export const clearGuidelines = (): GuidelineAction => ({ type: ActionType.CLEAR })

//
//	newGuidelinesTab()
//

export const newGuidelinesTab = (): GuidelineAction => ({ type: ActionType.NEW_TAB })

//
//	changeGuidelineTab()
//

export const changeGuidelineTab = (uuid: string): GuidelineAction => ({
  type: ActionType.CHANGE_TAB,
  uuid,
})

//
//	closeGuidelineTab()
//

export const closeGuidelineTab = (uuid: string): GuidelineAction => ({
  type: ActionType.CLOSE_TAB,
  uuid,
})

//
//	updateForm()
//

export const updateForm = (uuid: string, changes: Partial<Guideline>): GuidelineAction => ({
  type: ActionType.UPDATE_FORM,
  uuid,
  changes,
})

//
//  loadConfig()
//

export const loadConfig = (): GuidelineAction => ({ type: ActionType.LOAD_CONFIG_REQUEST })

export const loadConfigSuccess = (config: GuidelineConfig): GuidelineAction => ({
  type: ActionType.LOAD_CONFIG_SUCCESS,
  config,
})

export const loadConfigFailure = (error: Error): GuidelineAction => ({
  type: ActionType.LOAD_CONFIG_FAILURE,
  error,
})

//
//  createConfig()
//

export const createConfig = (config: GuidelineConfig): GuidelineAction => ({
  type: ActionType.CREATE_CONFIG_REQUEST,
  config,
})

export const createConfigSuccess = (config: GuidelineConfig): GuidelineAction => ({
  type: ActionType.CREATE_CONFIG_SUCCESS,
  config,
})

export const createConfigFailure = (error: Error): GuidelineAction => ({
  type: ActionType.CREATE_CONFIG_FAILURE,
  error,
})

//
//  updateConfig()
//

export const updateConfig = (config: GuidelineConfig): GuidelineAction => ({
  type: ActionType.UPDATE_CONFIG_REQUEST,
  config,
})

export const updateConfigSuccess = (config: GuidelineConfig): GuidelineAction => ({
  type: ActionType.UPDATE_CONFIG_SUCCESS,
  config,
})

export const updateConfigFailure = (error: Error): GuidelineAction => ({
  type: ActionType.UPDATE_CONFIG_FAILURE,
  error,
})

//
//  wsCreateGuideline()
//

export const wsCreateGuideline = (guideline: Guideline): GuidelineAction => ({
  type: ActionType.WS_CREATE,
  guideline,
})

//
//  wsUpdateGuideline()
//

export const wsUpdateGuideline = (
  guideline: Guideline,
  onlyUpdateProduction?: boolean,
): GuidelineAction => ({
  type: ActionType.WS_UPDATE,
  guideline,
  onlyUpdateProduction,
})

//
//  wsDeleteGuideline()
//

export const wsDeleteGuideline = (ids: number[]): GuidelineAction => ({
  type: ActionType.WS_DELETE,
  ids,
})

//
//  wsPatchGuideline()
//

export const wsPatchGuideline = (
  guidelineId: number,
  changes: Partial<Guideline>,
): GuidelineAction => ({
  type: ActionType.WS_PATCH,
  guidelineId,
  changes,
})
