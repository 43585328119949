import React, { memo, useState, useCallback, useMemo, useEffect } from 'react'

import styled from '@emotion/styled'

import Button from 'antd/es/button'
import Drawer from 'antd/es/drawer'
import Modal from 'antd/es/modal'
import Spin from 'antd/es/spin'
import Tooltip from 'antd/es/tooltip'
import { TablePaginationConfig } from 'antd/es/table'

import isEqual from 'react-fast-compare'

import { connect } from 'react-redux'

import { LoadingOutlined } from '@ant-design/icons'

import { documentApi, guidelineApi } from '@anews/api'
import { useOnce } from '@anews/hooks'
import { Reportage, Guideline, Program, Page } from '@anews/types'
import { todayDate, momentToDate, displayDate, dateToMoment, mapJoin } from '@anews/utils'

import { HGroup, DatePicker, VGroup, CompactTable } from '../../global'
import GuidelineViewer from '../guidelines/GuidelineViewer'

import {
  CompanyActions,
  DocumentActions,
  ProgramActions,
  TypeActions,
  UserActions,
  VehicleActions,
} from '../../../redux/actions'

import * as GuidelineActions from '../../../redux/actions/guideline-actions'
import * as ClassificationActions from '../../../redux/actions/classification-actions'
import * as EditorialActions from '../../../redux/actions/editorial-actions'

import { classificationsMap as createClassificationMap } from '../../../redux/selectors/classification-selectors'
import { companiesMap as createCompaniesMap } from '../../../redux/selectors/company-selectors'
import { editorialsMap as createEditorialsMap } from '../../../redux/selectors/editorial-selectors'
import { programsMap as createProgramsMap } from '../../../redux/selectors/program-selectors'
import { typesMap as createTypesMap } from '../../../redux/selectors/type-selectors'
import { allUsersMap as createUsersMap } from '../../../redux/selectors/user-selectors'
import { vehiclesMap as createVehiclesMap } from '../../../redux/selectors/vehicle-selectors'
import { RootState, PropsMapper } from '../../../redux/reducers'
import { useTranslation } from '../../../i18n'
import { PAGE_SIZE } from '../../../consts/pagination'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const ViewerContainer = styled.div`
  width: 100%;
  .document-excluded {
    color: red;
    margin-left: 24px !important;
    font-size: 16px;
  }
`

interface OwnProps {
  visible: boolean
  setVisible: (v: boolean) => void
  reportage: Reportage
  programs: Program[]
}

type Props = PropsMapper<typeof mapStateToProps, typeof dispatchProps, OwnProps>

const ReportageHistoryDrawer = ({
  visible,
  setVisible,
  reportage,
  programs,
  programsMap,
  guidelineConfig,
  documentConfig,
  ...props
}: Props) => {
  const { t } = useTranslation()

  const [visibleModal, setVisibleModal] = useState(false)
  const [filterParams, setFilterParams] = useState({ from: todayDate(), to: todayDate() })
  const [pageResult, setPageResult] = useState<Page<Guideline>>()
  const [page, setPage] = useState<number>(0)
  const [selectedGuidelineId, setSelectedGuidelineId] = useState(-1)
  const [associatedGuideline, setAssociatedGuideline] = useState<Guideline>()
  const [loadingAssociations, setLoadingAssociations] = useState(false)

  // Filter handlers
  const onFromChange = useCallback(momentDate => {
    setFilterParams(prev => ({ ...prev, from: momentToDate(momentDate) || '' }))
  }, [])

  const onToChange = useCallback(momentDate => {
    setFilterParams(prev => ({ ...prev, to: momentToDate(momentDate) || '' }))
  }, [])

  // Modal handlers
  const onCancel = useCallback(() => {
    setVisibleModal(false)
  }, [])

  const onPageChange = (pageId: number) => {
    setPage(pageId - 1)
  }

  useOnce(() => {
    setLoadingAssociations(true)
    documentApi
      .getAssociations(reportage.id)
      .then(data => setAssociatedGuideline(data.assignment))
      .catch(console.error)
      .finally(() => {
        setLoadingAssociations(false)
      })
  })

  const onOk = useCallback(() => {
    if (selectedGuidelineId > -1) {
      setLoadingAssociations(true)
      documentApi
        .associateDocument(reportage.id, selectedGuidelineId)
        // Carrega pauta para visualização
        .then(async () => {
          const guideline = await guidelineApi.load(selectedGuidelineId, false)
          setAssociatedGuideline(guideline)
        })
        .catch(console.error)
        .finally(() => {
          setVisibleModal(false)
          setSelectedGuidelineId(-1)
          setLoadingAssociations(false)
        })
    }
  }, [reportage.id, selectedGuidelineId])

  const removeAssociation = useCallback(() => {
    documentApi
      .removeAssociation(reportage.id)
      .then(() => {
        setAssociatedGuideline(undefined)
      })
      .catch(console.error)
  }, [reportage.id])

  // Table handlers
  useEffect(() => {
    // Carrega as pautas de acordo com as datas
    const filterGuidelines = async () => {
      try {
        setPageResult(
          await guidelineApi.filter({ ...filterParams, allPrograms: true }, page, PAGE_SIZE),
        )
      } catch (error) {
        console.error(error)
      }
    }
    filterGuidelines()
  }, [filterParams, page, setPageResult])

  const pagination: TablePaginationConfig = {
    onChange: onPageChange,
    current: page + 1,
    pageSize: PAGE_SIZE,
    total: pageResult?.total,
  }

  // Seleção pelo checkbox... dispara o onSelectionChange sem ativar a flag que limpa as seleções
  const rowSelection = {
    onChange: (keys: (number | string)[]) => {
      if (Array.isArray(keys)) {
        if (keys.length > 1) {
          setSelectedGuidelineId(keys[keys.length - 1] as number)
        }
      }
    },
    selectedRowKeys: [selectedGuidelineId],
  }

  const columns = useMemo(
    () => [
      {
        title: t('document:type'),
        dataIndex: 'type',
        key: 'type',
        render: () => t('words:assignment'),
      },
      {
        title: t('words:date'),
        dataIndex: 'date',
        key: 'date',
        render: (date: any) => displayDate(date),
      },
      { title: t('words:slug'), dataIndex: 'slug', key: 'slug' },
      {
        title: t('words:programs'),
        dataIndex: 'programsIds',
        key: 'programs',

        render: ids => mapJoin(programsMap, ids) || t('program:drawer'),
      },
    ],
    [programsMap, t],
  )

  // Guideline Viewer handlers
  const {
    loadConfig,
    listAccessiblePrograms,
    listAllPrograms,
    listAllUsers,
    listClassifications,
    listCompanies,
    listReporters,
    listEditorials,
    listEditors,
    listProducers,
    listTypes,
    listVehicles,
    loadDocumentConfig,
    ...viewerProps
  } = props

  useOnce(loadConfig)
  useOnce(loadDocumentConfig)
  useOnce(listAllUsers)
  useOnce(listProducers)
  useOnce(listAllPrograms)
  useOnce(listAccessiblePrograms)

  // Praças
  useEffect(() => {
    if (documentConfig && documentConfig.branchEnabled) {
      listCompanies()
    }
  }, [documentConfig, listCompanies])

  useEffect(() => {
    if (guidelineConfig) {
      // Classificações
      if (guidelineConfig.classificationEnabled) {
        listClassifications()
      }
      // Editores
      if (guidelineConfig.editorsEnabled) {
        listEditors()
      }
      // Repórters
      if (guidelineConfig.reportersEnabled) {
        listReporters()
      }
      // Editorias
      if (guidelineConfig.editorialsEnabled) {
        listEditorials()
      }
      // Tipos
      if (guidelineConfig.typeEnabled) {
        listTypes()
      }
      // Veículos
      if (guidelineConfig.vehiclesEnabled) {
        listVehicles()
      }
    }
  }, [
    guidelineConfig,
    listClassifications,
    listEditorials,
    listEditors,
    listReporters,
    listTypes,
    listVehicles,
  ])

  return (
    <>
      <Drawer
        title={t('words:history')}
        visible={visible}
        mask={false}
        maskClosable={false}
        onClose={() => setVisible(false)}
        width={!associatedGuideline ? 350 : 640}
        bodyStyle={{ padding: 6 }}
      >
        {loadingAssociations ? (
          <Container>
            <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </Container>
        ) : (
          <>
            {associatedGuideline ? (
              <ViewerContainer>
                <HGroup style={{ flexGrow: 1, justifyContent: 'space-between' }}>
                  <span className="document-excluded">
                    {associatedGuideline.excluded && t('document:excluded')}
                  </span>
                  <span>
                    <Button onClick={removeAssociation} style={{ marginRight: 8 }}>
                      {t('words:remove')}
                    </Button>
                    <Button onClick={() => setVisibleModal(true)}>{t('words:reassociate')}</Button>
                  </span>
                </HGroup>
                <GuidelineViewer
                  guideline={associatedGuideline}
                  editable={false}
                  programs={programsMap}
                  {...viewerProps}
                />
              </ViewerContainer>
            ) : (
              <Container>
                <Button size="large" onClick={() => setVisibleModal(true)}>
                  {t('words:associate')}
                </Button>
              </Container>
            )}
          </>
        )}
      </Drawer>

      <Modal
        visible={visibleModal}
        title={`${t('words:select')} ${t('words:assignment').toLocaleLowerCase()}`}
        width={700}
        okButtonProps={{ disabled: selectedGuidelineId === -1 }}
        onOk={onOk}
        onCancel={onCancel}
      >
        <VGroup style={{ width: '100%' }}>
          <HGroup style={{ flexGrow: 1 }}>
            <Tooltip title={t('main:fromDate')}>
              <DatePicker
                onChange={onFromChange}
                placeholder={t('main:fromDate')}
                value={dateToMoment(filterParams.from)}
              />
            </Tooltip>
            <Tooltip title={t('main:toDate')}>
              <DatePicker
                onChange={onToChange}
                placeholder={t('main:toDate')}
                value={dateToMoment(filterParams.to)}
              />
            </Tooltip>
          </HGroup>

          <CompactTable
            rowKey="id"
            dataSource={pageResult?.data}
            columns={columns}
            pagination={pagination}
            rowSelection={rowSelection}
            onRow={guideline => ({
              onClick: () => setSelectedGuidelineId(guideline.id),
            })}
            autoScroll
          />
        </VGroup>
      </Modal>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  documentConfig: state.documents.config.data,
  guidelineConfig: state.guidelines.config.data,
  classifications: createClassificationMap(state),
  companies: createCompaniesMap(state),
  editorials: createEditorialsMap(state),
  programsMap: createProgramsMap(state),
  types: createTypesMap(state),
  users: createUsersMap(state),
  vehicles: createVehiclesMap(state),
})

const dispatchProps = {
  loadConfig: GuidelineActions.loadConfig,
  loadDocumentConfig: DocumentActions.loadConfig,
  listClassifications: ClassificationActions.listClassifications,
  listCompanies: CompanyActions.listCompanies,
  listEditorials: EditorialActions.listEditorials,
  listAllPrograms: ProgramActions.listAllPrograms,
  listAccessiblePrograms: ProgramActions.listAccessiblePrograms,
  listTypes: TypeActions.listTypes,
  listAllUsers: UserActions.listAllUsers,
  listEditors: UserActions.listEditors,
  listProducers: UserActions.listProducers,
  listReporters: UserActions.listReporters,
  listVehicles: VehicleActions.listVehicles,
}

export default connect(mapStateToProps, dispatchProps)(memo(ReportageHistoryDrawer, isEqual))
