/* eslint-disable react/no-array-index-key */
import React from 'react'

import styled from '@emotion/styled'

import { RichTextBlock, RichTextLeaf } from '@anews/types'
import { highlight } from '@anews/utils'

import { richTextPalette } from './RichTextEditor'

interface Props {
  content?: RichTextBlock[]
}

const Leaf = styled.span<{
  bold: boolean
  italic: boolean
  color: string
  underline: boolean
  comment?: boolean
}>`
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  color: ${({ color, comment }) => (comment ? richTextPalette.comment : color)};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
`

function RawTextViewer({ content }: Props) {
  return (
    <>
      {content &&
        content.map((element, ei) => {
          if (element.type === 'paragraph') {
            const children = element.children as RichTextLeaf[]
            if (children.length === 1 && children[0].text === '') {
              return <br key={ei} />
            }
            return (
              <p key={ei}>
                {children.map((leaf, ci) => {
                  const isComment = leaf.text.match(/(\[+[^\]]*\])/g)
                  return (
                    <Leaf key={ci} {...leaf} comment={!!isComment}>
                      {highlight(leaf.text)}
                    </Leaf>
                  )
                })}
              </p>
            )
          }
          return null
        })}
    </>
  )
}

export default React.memo(RawTextViewer)
