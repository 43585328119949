var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
// Verifica se dois arrays são iguais, fazendo comparação simples dos elementos
// Se tiver os mesmos elementos em ordem diferentes, os arrays são diferentes
export function equals(a, b) {
    if (a === b) {
        return true;
    }
    if (a.length !== b.length) {
        return false;
    }
    for (var i = 0; i < a.length; i += 1) {
        if (a[i] !== b[i]) {
            return false;
        }
    }
    return true;
}
// Encontra o elemento que fica antes do indicado
// Se o elemento estiver no início, o anterior é quem está no final
// "test" pode ser uma função que testa a igualdade ou o element direto
export function previous(array, test) {
    var index = typeof test === 'function' ? array.findIndex(function (v) { return test(v); }) : array.indexOf(test);
    return index === -1 ? undefined : array[index === 0 ? array.length - 1 : index - 1];
}
// Retorna uma cópia ordenada do array
export function sort(array, compareFunction) {
    var copy = __spreadArray([], __read(array));
    copy.sort(compareFunction);
    return copy;
}
export function conditionalBuilder(source) {
    var array = source ? __spreadArray([], __read(source)) : [];
    return {
        pushIf: function (condition) {
            var items = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                items[_i - 1] = arguments[_i];
            }
            var trues = condition.filter(function (value) { return !!value; });
            if (trues.length === condition.length) {
                array.push.apply(array, __spreadArray([], __read(items)));
            }
            return this;
        },
        push: function () {
            var items = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                items[_i] = arguments[_i];
            }
            array.push.apply(array, __spreadArray([], __read(items)));
            return this;
        },
        build: function () {
            return array;
        },
    };
}
