var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { del, get, query, patch, post, put } from './client';
var endpoint = '/api/assignments';
var guidelineApi = {
    dateList: function (date, programId, allPrograms) {
        return get(query(endpoint, { date: date, programId: programId, allPrograms: allPrograms }));
    },
    filter: function (params, page, size) {
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 25; }
        return get(query(endpoint + "/filter", __assign(__assign({}, params), { page: page, size: size })));
    },
    counter: function (filter) {
        return get(query(endpoint + "/counter", __assign({}, filter)));
    },
    listExcluded: function (filter, page, size) {
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 25; }
        return get(query(endpoint + "/excluded", __assign(__assign({}, filter), { page: page, size: size })));
    },
    load: function (id, lock) {
        return get(query(endpoint + "/" + id, { lock: lock }));
    },
    create: function (guideline, lock) {
        return post(query(endpoint, { lock: lock }), guideline);
    },
    copy: function (ids, programsIds, date) {
        return post(query(endpoint + "/copy", { ids: ids, programsIds: programsIds, date: date }));
    },
    createFromNews: function (newsId, date, programsIds) {
        return post(query(endpoint + "/proposal/" + newsId, { date: date, programsIds: programsIds }));
    },
    createFromRound: function (newsId, date, programsIds) {
        return post(query(endpoint + "/round/" + newsId, { date: date, programsIds: programsIds }));
    },
    createFromSouvDocument: function (workspaceId, souvDocumentId, date, programsIds) {
        return post(query(endpoint + "/souv/" + souvDocumentId, { workspaceId: workspaceId, date: date, programsIds: programsIds }));
    },
    update: function (guideline) {
        return put(endpoint, guideline);
    },
    updateField: function (id, field, value) {
        var _a;
        // Enviar undefined não funciona para limpar o valor
        return patch(endpoint + "/" + id, (_a = {}, _a[field] = value === undefined ? null : value, _a));
    },
    updateState: function (ids, state) {
        return patch(query(endpoint + "/states", { ids: ids, state: state }));
    },
    remove: function (ids) {
        return del(query(endpoint, { ids: ids }));
    },
    restore: function (ids, date, programsIds) {
        return put(query(endpoint + "/restore", { ids: ids, date: date, programsIds: programsIds }));
    },
    groupings: function () {
        return get(endpoint + "/groupings");
    },
    loadChangesHistory: function (id) {
        return get(query(endpoint + "/" + id + "/revisions"));
    },
    loadRevision: function (id, revisionNumber, showDiff) {
        return get(query(endpoint + "/" + id + "/revisions/" + revisionNumber, { showDiff: showDiff }));
    },
    sendEmails: function (ids, usersIds, groupedEmail, includeEditors, includeReporters, includeTeams) {
        return post(query(endpoint + "/email", {
            ids: ids,
            usersIds: usersIds,
            groupedEmail: groupedEmail,
            includeEditors: includeEditors,
            includeReporters: includeReporters,
            includeTeams: includeTeams,
        }));
    },
    //
    //  config
    //
    loadConfig: function () {
        return get(endpoint + "/config");
    },
    createConfig: function (config) {
        return post(endpoint + "/config", config);
    },
    updateConfig: function (config) {
        return put(endpoint + "/config", config);
    },
};
export default guidelineApi;
