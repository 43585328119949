import React, { memo, ComponentType } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

// https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html

export interface AuthenticatedRouteProps extends Omit<RouteProps, 'component'> {
  authenticated: boolean
  component: ComponentType<any>
}

function AuthenticatedRoute({ component: C, authenticated, ...rest }: AuthenticatedRouteProps) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <C {...props} />
        ) : (
          <Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`} />
        )
      }
    />
  )
}

export default memo(AuthenticatedRoute)
