import { DocumentType, Story, StorySubSection, StorySubSectionType } from '@anews/types'
import { displayDate, formatIsoDuration, richToPlain } from '@anews/utils'

import i18n from '../../../../i18n'
import { SelectorMap } from '../../../../redux/selectors/helpers'
import { copyToClipboard } from '../../../../utils/clipboard-utils'
import { removeMarkTags } from '../../../../utils/xml-utils'

function formatAbstractText(str: string | undefined) {
  return removeMarkTags(str || '')
    .replace(/(\r\n|\n|\r)/gm, '') // remove as quebras de linha
    .replace(/\s+/g, ' ') // remove espaços duplos
}

function getCreditsContent(subSection: StorySubSection) {
  const creditsText = []

  if (subSection.type === StorySubSectionType.CG) {
    creditsText.push(`${i18n.t('story:cgItem')} `)

    if (subSection.code) {
      creditsText.push(` (${i18n.t('words:codeAbbr')} ${subSection.code}) `)
    }

    creditsText.push('- ')

    if (subSection.cgFields && subSection.cgFields?.length > 0) {
      subSection.cgFields.forEach(cgField => {
        creditsText.push(`${cgField.name}: `)
        if (cgField.value) {
          creditsText.push(`${cgField.value} `)
        }
      })
    } else {
      creditsText.push(subSection.templateName)
    }
  }

  if (subSection.type === StorySubSectionType.MOS_CG) {
    if (subSection.slug) {
      creditsText.push(`${subSection.slug}`)
    }

    if (subSection.media?.abstractText) {
      creditsText.push(`- ${formatAbstractText(subSection.media?.abstractText)}`)
    }
  }

  return creditsText.join('')
}

function minuteSecondToString(dur: string) {
  return formatIsoDuration(dur, 'mm:ss')
}

// Texto de User, Cam, Duration e Text
function getUserCamDurText(sub: StorySubSection, users: SelectorMap<string>) {
  const text = []

  if (sub) {
    if (sub.camera && sub.camera > 0) {
      text.push(`[CAM ${sub.camera}] `)
    }

    if (sub.presenterId) {
      text.push(`[${users[sub.presenterId]}] `)
    }

    if (sub.duration) {
      text.push(`${minuteSecondToString(sub.duration)}`)
    }

    if (sub.text) {
      text.push(`\n${richToPlain(sub.text)}`)
    }

    text.push('\n')
  }

  return text.join('')
}

// Texto cabeça
function getHeadContent(sub: StorySubSection, users: SelectorMap<string>) {
  const headText = []

  if (sub) {
    headText.push(getUserCamDurText(sub, users))

    sub.subSections.forEach((subSub: StorySubSection) => {
      if (subSub.type === StorySubSectionType.CG || subSub.type === StorySubSectionType.MOS_CG) {
        headText.push(`${getCreditsContent(subSub)} \n`)
      } else if (subSub.type === StorySubSectionType.MOS_VIDEO) {
        if (subSub.duration) {
          headText.push(` (${minuteSecondToString(subSub.duration)}) ${subSub.slug}`)
          if (subSub.status) {
            headText.push(` (${subSub.status}) `)
          }
          headText.push('\n')
        }
      }
    })
  }

  return headText.join('')
}

function getMediaContent(sub: StorySubSection) {
  const mediaText = []

  if (sub.type === StorySubSectionType.CG || sub.type === StorySubSectionType.MOS_CG) {
    mediaText.push(`${getCreditsContent(sub)}`)
  } else if (sub.type === StorySubSectionType.MOS_VIDEO) {
    if (sub.duration) {
      mediaText.push(` (${minuteSecondToString(sub.duration)}) ${sub.slug} \n`)
    }
  }
  return mediaText.join('')
}

export default function storyToClipboard(
  story: Story,
  {
    programs,
    types,
    users,
  }: {
    programs: SelectorMap<string>
    types: SelectorMap<Partial<DocumentType>>
    users: SelectorMap<string>
  },
) {
  const {
    headSection,
    vtSection,
    liveVOSection,
    footerSection,
    recordedVOSection,
    linkSection,
    information,
    programId,
    typeId,
  } = story

  const storyText = []

  if (programId) {
    storyText.push(`${i18n.t('words:program')} : ${programs[programId]}`)
  }

  storyText.push(`${i18n.t('words:date')} : ${displayDate(story.date)}`)
  storyText.push(`${i18n.t('story:page')} : ${story.page}`)

  if (typeId) {
    storyText.push(`${i18n.t('words:type')} : ${types[typeId].abbreviation}`)
  }

  storyText.push(`${i18n.t('words:slug')} : ${story.slug}\n`)

  // Cabeça
  if (headSection) {
    if (headSection.duration) {
      storyText.push(`${i18n.t('story:head')} : ${minuteSecondToString(headSection.duration)}`)
    }
    headSection.subSections.forEach(sub => {
      storyText.push(getHeadContent(sub, users))
    })
  }

  // VT
  if (vtSection) {
    if (vtSection.duration) {
      storyText.push(`\n${i18n.t('story:vt')} : ${minuteSecondToString(vtSection.duration)}`)
    }
    vtSection.subSections.forEach(sub => {
      storyText.push(getMediaContent(sub))
    })

    const cue = []
    if (vtSection.cueIn) {
      cue.push(`${i18n.t('story:cueIn')} : ${vtSection.cueIn}`)
    }

    if (vtSection.cueOut) {
      cue.push(`${i18n.t('story:cueOut')} : ${vtSection.cueOut}`)
    }

    storyText.push(cue.join('\n'))
  }

  // link
  if (linkSection) {
    if (linkSection.duration) {
      storyText.push(`\n${i18n.t('story:link')} : ${minuteSecondToString(linkSection.duration)}`)
    }

    linkSection.subSections.forEach(sub => {
      const linkContent = []
      linkContent.push(getUserCamDurText(sub, users))
      linkContent.push(getMediaContent(sub))

      storyText.push(linkContent.join(''))
    })
  }

  // liveVO
  if (liveVOSection) {
    const contentLiveVO = []
    if (liveVOSection.duration) {
      contentLiveVO.push(`${i18n.t('story:nc')} ${minuteSecondToString(liveVOSection.duration)}\n`)
    }
    contentLiveVO.push(`[${i18n.t('story:imageIn')}]\n`)

    liveVOSection.subSections.forEach(sub => {
      contentLiveVO.push(`${getUserCamDurText(sub, users)}`)
      contentLiveVO.push(getMediaContent(sub))
    })

    contentLiveVO.push(`\n[${i18n.t('story:imageOut')}]\n`)
    storyText.push(contentLiveVO.join(''))
  }

  // Nota pé
  if (footerSection) {
    const contentFooter = []

    contentFooter.push(`\n${i18n.t('story:footnote')}\n`)

    footerSection.subSections.forEach(sub => {
      contentFooter.push(getUserCamDurText(sub, users))

      sub.subSections.forEach(subSub => {
        contentFooter.push(`${getMediaContent(subSub)}\n`)
      })
    })

    storyText.push(contentFooter.join(''))
  }

  // Off
  if (recordedVOSection) {
    const recordedVOContent = []
    recordedVOContent.push(`\n${i18n.t('story:off')}\n`)

    recordedVOSection.subSections.forEach(sub => {
      recordedVOContent.push(`${getUserCamDurText(sub, users)}`)
    })

    storyText.push(recordedVOContent.join(''))
  }

  // Informações
  if (information) {
    storyText.push(`${i18n.t('words:info')} : ${information}`)
  }

  return copyToClipboard(
    removeMarkTags(storyText.join('\n').toString()),
    i18n.t('phrases:copiedToClipboard'),
    i18n.t('error:actionFailed'),
  )
}
