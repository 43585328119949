import { createSelector } from 'reselect'

import { Company, Team, DocumentType } from '@anews/types'

import { RootState } from '../reducers'

import { UsersListState } from '../reducers/users-reducer'

import { createMapSelector, SelectorMap } from './helpers'

const getCompanies = (state: RootState) => state.companies.list.data

const getRemotePrograms = (state: RootState, props: { companyId: number }) =>
  props.companyId && state.companies.programs[props.companyId]
    ? state.companies.programs[props.companyId].data
    : []

const getRemoteClassifications = (state: RootState, props: { companyId: number }) =>
  props.companyId && state.companies.classifications[props.companyId]
    ? state.companies.classifications[props.companyId].data
    : []

const getRemoteUsers = (state: RootState, props: { companyId: number }) =>
  props.companyId && state.companies.users[props.companyId]
    ? state.companies.users[props.companyId].data
    : []

const getRemoteVehicles = (state: RootState, props: { companyId: number }) =>
  props.companyId && state.companies.vehicles[props.companyId]
    ? state.companies.vehicles[props.companyId].data
    : []

const getRemoteCompanies = (state: RootState, props: { companyId: number }) =>
  props.companyId && state.companies.remoteCompanies[props.companyId]
    ? state.companies.remoteCompanies[props.companyId].data
    : []

const getRemoteEditorials = (state: RootState, props: { companyId: number }) =>
  props.companyId && state.companies.editorials[props.companyId]
    ? state.companies.editorials[props.companyId].data
    : []

const getRemoteTeams = (state: RootState, props: { companyId: number }) =>
  props.companyId && state.companies.teams[props.companyId]
    ? state.companies.teams[props.companyId].data
    : []

const getRemoteTypes = (state: RootState, props: { companyId: number }) =>
  props.companyId && state.companies.types[props.companyId]
    ? state.companies.types[props.companyId].data
    : []

export const getRemoteImageEditors = (state: RootState, props: { companyId: number }) => {
  const users =
    (props.companyId && state.companies.users[props.companyId]) || ({} as UsersListState)
  return { ...users, data: (users?.data || []).filter(u => !u.excluded) }
}

export const companiesMap = createMapSelector<Company, string>(getCompanies, 'id', 'name')

export const getRemoteUsersMap = createSelector([getRemoteUsers], users => {
  const map: SelectorMap<string> = {}
  for (let idx = 0; idx < users.length; idx += 1) {
    const user = users[idx]
    map[user.id] = user.name
  }
  return map
})

export function makeGetRemoteProgramsMap(getPrograms = getRemotePrograms) {
  return createSelector([getPrograms], programs => {
    const map: SelectorMap<string> = {}
    programs.forEach(program => {
      map[program.id] = program.name
    })
    return map
  })
}

export function makeGetRemoteClassificationsMap(getClassifications = getRemoteClassifications) {
  return createSelector([getClassifications], classifications => {
    const map: SelectorMap<string> = {}
    classifications.forEach(classification => {
      map[classification.id] = classification.name
    })
    return map
  })
}

export function makeGetRemoteVehiclesMap(getVehicles = getRemoteVehicles) {
  return createSelector([getVehicles], vehicles => {
    const map: SelectorMap<string> = {}
    vehicles.forEach(vehicle => {
      map[vehicle.id] = vehicle.name
    })
    return map
  })
}

export function makeGetRemoteCompaniesMap(getRemote = getRemoteCompanies) {
  return createSelector([getRemote], companies => {
    const map: SelectorMap<string> = {}
    companies.forEach(company => {
      map[company.id] = company.name
    })
    return map
  })
}

export function makeGetRemoteEditorialsMap(getEditorials = getRemoteEditorials) {
  return createSelector([getEditorials], editorials => {
    const map: SelectorMap<string> = {}
    editorials.forEach(editorial => {
      map[editorial.id] = editorial.name
    })
    return map
  })
}

export function makeGetRemoteTeamsMap(getTeams = getRemoteTeams) {
  return createSelector([getTeams], teams => {
    const map: SelectorMap<Partial<Team>> = {}
    teams.forEach(team => {
      map[team.id] = { name: team.name, membersNicknames: team.membersNicknames }
    })
    return map
  })
}

export function makeGetRemoteTypesMap(getTypes = getRemoteTypes) {
  return createSelector([getTypes], types => {
    const map: SelectorMap<Partial<DocumentType>> = {}
    types.forEach(type => {
      map[type.id] = { abbreviation: type.abbreviation, color: type.color }
    })
    return map
  })
}
