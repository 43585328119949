import { Reducer } from 'redux'

import { User } from '@anews/types'

import { AuthAction, AuthActionType, UserAction, UserActionType } from '../actions'

export interface UsersListState {
  data: User[]
  loading: boolean
}

export interface UsersListsByCategoryState {
  all: UsersListState
  active: UsersListState
  editors: UsersListState
  imageEditors: UsersListState
  producers: UsersListState
  reporters: UsersListState
}

export type UserCategory = keyof UsersListsByCategoryState

export interface UserPageState {
  data: User[]
  number: number
  size: number
  total: number
  filter: string
  loading: boolean
  activeOnly: boolean
}

export interface UsersState {
  lists: UsersListsByCategoryState
  page: UserPageState
  editing?: User
  saving: boolean
  disabling: boolean
}

export const initialState: UsersState = {
  lists: {
    all: { data: [], loading: false },
    active: { data: [], loading: false },
    editors: { data: [], loading: false },
    imageEditors: { data: [], loading: false },
    producers: { data: [], loading: false },
    reporters: { data: [], loading: false },
  },
  page: {
    data: [],
    number: 0,
    size: 0,
    total: 0,
    filter: '',
    activeOnly: true,
    loading: true,
  },
  saving: false,
  disabling: false,
}

function pageReducer(page: UserPageState, action: UserAction | AuthAction): UserPageState {
  switch (action.type) {
    case UserActionType.FILTER_REQUEST:
      return { ...page, filter: action.filter, activeOnly: action.activeOnly, loading: true }

    case UserActionType.FILTER_FAILURE:
      return { ...page, loading: false }

    case UserActionType.FILTER_SUCCESS:
      return {
        ...page,
        data: action.result.data,
        size: action.result.size,
        total: action.result.total,
        number: action.result.page,
        loading: false,
      }

    case UserActionType.CREATE_SUCCESS:
      return { ...page, data: [action.user, ...page.data] }

    case UserActionType.UPDATE_SUCCESS:
    case UserActionType.RESTORE_SUCCESS:
    case UserActionType.RESET_PASSWORD_SUCCESS:
      return {
        ...page,
        data: page.data.map(user => (user.id === action.user.id ? action.user : user)),
      }

    case UserActionType.REMOVE_SUCCESS:
      return {
        ...page,
        data: page.data.filter(u => action.ids.includes(u.id)),
      }

    case UserActionType.WS_DELETE:
      if (page.activeOnly) {
        return { ...page, data: page.data.filter(u => u.id !== action.id) }
      }
      return {
        ...page,
        data: page.data.map(u => (u.id === action.id ? { ...u, excluded: true } : u)),
      }

    // KickOut
    case AuthActionType.KICKOUT_SUCCESS:
      return {
        ...page,
        data: page.data.map(user => (user.id === action.id ? { ...user, online: false } : user)),
      }

    default:
      return page
  }
}

function deleteUserFromList(list: UsersListState, userId: number): UsersListState {
  return { ...list, data: list.data.filter(u => u.id !== userId) }
}

function listsReducer(
  lists: UsersListsByCategoryState,
  action: UserAction,
): UsersListsByCategoryState {
  switch (action.type) {
    case UserActionType.LIST_BY_CATEGORY_REQUEST:
      return { ...lists, [action.category]: { ...lists[action.category], loading: true } }

    case UserActionType.LIST_BY_CATEGORY_FAILURE:
      return { ...lists, [action.category]: { ...lists[action.category], loading: false } }

    case UserActionType.LIST_BY_CATEGORY_SUCCESS:
      return {
        ...lists,
        [action.category]: { ...lists[action.category], loading: false, data: action.users },
      }

    case UserActionType.WS_DELETE:
      return {
        all: deleteUserFromList(lists.all, action.id),
        active: deleteUserFromList(lists.active, action.id),
        editors: deleteUserFromList(lists.editors, action.id),
        imageEditors: deleteUserFromList(lists.imageEditors, action.id),
        producers: deleteUserFromList(lists.producers, action.id),
        reporters: deleteUserFromList(lists.reporters, action.id),
      }

    default:
      return lists
  }
}

const usersReducer: Reducer<UsersState, UserAction | AuthAction> = (
  state = initialState,
  action,
): UsersState => {
  switch (action.type) {
    case UserActionType.FILTER_REQUEST:
    case UserActionType.FILTER_FAILURE:
    case UserActionType.FILTER_SUCCESS:
    case UserActionType.CREATE_SUCCESS:
    case UserActionType.UPDATE_SUCCESS:
    case UserActionType.RESET_PASSWORD_SUCCESS:
    case UserActionType.RESTORE_SUCCESS:
    case UserActionType.REMOVE_SUCCESS:
    case AuthActionType.KICKOUT_SUCCESS:
      return {
        ...state,
        page: pageReducer(state.page, action),
        editing: undefined,
        saving: false,
        disabling: false,
      }

    case UserActionType.NEW:
    case UserActionType.EDIT_SUCCESS:
      return { ...state, editing: action.user }

    case UserActionType.CREATE_REQUEST:
    case UserActionType.UPDATE_REQUEST:
      return { ...state, editing: action.user, saving: true }

    case UserActionType.RESET_PASSWORD_REQUEST:
      return { ...state, saving: true }

    case UserActionType.CREATE_FAILURE:
    case UserActionType.UPDATE_FAILURE:
    case UserActionType.RESET_PASSWORD_FAILURE:
      return { ...state, saving: false }

    case UserActionType.REMOVE_REQUEST:
      return { ...state, disabling: true }

    case UserActionType.LIST_BY_CATEGORY_REQUEST:
    case UserActionType.LIST_BY_CATEGORY_FAILURE:
    case UserActionType.LIST_BY_CATEGORY_SUCCESS:
      return { ...state, lists: listsReducer(state.lists, action) }

    case UserActionType.WS_DELETE:
      return {
        ...state,
        lists: listsReducer(state.lists, action),
        page: pageReducer(state.page, action),
      }

    default:
      return state
  }
}

export default usersReducer
