import reactHtmlParser from 'react-html-parser';
export function escapeTags(value, preserverMarks) {
    if (preserverMarks === void 0) { preserverMarks = true; }
    var escaped = value.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return preserverMarks
        ? escaped.replaceAll('&lt;mark&gt;', '<mark>').replaceAll('&lt;/mark&gt;', '</mark>')
        : escaped;
}
export function highlight(value) {
    return value ? reactHtmlParser(escapeTags(value)) : value;
}
