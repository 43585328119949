import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { CacheProvider, ThemeProvider } from '@emotion/react'
import createCache from '@emotion/cache'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import ConfigProvider from 'antd/es/config-provider'
import { Locale } from 'antd/es/locale-provider'

import enUS from 'antd/es/locale-provider/en_US'
import esES from 'antd/es/locale-provider/es_ES'
import ptBR from 'antd/es/locale-provider/pt_BR'

import moment from 'moment'
import 'moment/locale/pt-br'
import 'moment/locale/es'

import { branchApi } from '@anews/api'
import { useLocalStorage } from '@anews/hooks'

import { StompProvider } from './hooks/useSubscription'
import store from './redux/store'

import i18n from './i18n'
import { setTag } from './sentry'
import themeDef from './theme'
import { UnsavedDataProvider } from './unsaved'

const emotionCache = createCache({
  key: 'arion',
})
// https://github.com/emotion-js/emotion/issues/1105#issuecomment-557726922
emotionCache.compat = true

const localesMap: { [x: string]: { moment: string; configProvider: Locale } } = {
  en_US: { moment: 'en-us', configProvider: enUS },
  es_ES: { moment: 'es', configProvider: esES },
  pt_BR: { moment: 'pt-br', configProvider: ptBR },
}

const AppProviders: React.FC = ({ children }) => {
  const [locale, setLocale] = useLocalStorage('i18nextLng', 'pt_BR') // a chave é a utilizada pelo i18next-browser-languageDetector

  useEffect(() => {
    // Carrega o locale somente no primeiro render
    branchApi
      .loadConfig()
      .then(config => {
        setLocale(config.locale)
        setTag('customer', config.branchName)
      })
      .catch(error => {
        console.error('Failed to load branch config', error)
        setLocale('pt_BR') // se falhar usa pt_BR
      })
  }, [setLocale])

  useEffect(() => {
    i18n.changeLanguage(locale.replace(/_.+/, ''))
    moment.locale(localesMap[locale]?.moment)
  }, [locale])

  return (
    <Provider store={store}>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={themeDef}>
          <StompProvider>
            <UnsavedDataProvider>
              <ConfigProvider locale={localesMap[locale]?.configProvider}>
                <DndProvider backend={HTML5Backend}>{children}</DndProvider>
              </ConfigProvider>
            </UnsavedDataProvider>
          </StompProvider>
        </ThemeProvider>
      </CacheProvider>
    </Provider>
  )
}
export default AppProviders
